<template>
  <v-container fluid>
    <v-row class="pt-7">
      <v-col cols="12">
        <h1 class="primary--text">Mis trámites</h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-alert color="primary" type="info" text>
          Aquí encontrarás todos los trámites que has iniciado en el
          Ayuntamiento de Tepic
        </v-alert>
      </v-col>
    </v-row>
    <v-row v-if="isLoading">
      <v-col cols="12" class="align-center justify-center d-flex py-12">
        <v-progress-circular
          size="40"
          color="primary"
          indeterminate
        ></v-progress-circular>
      </v-col>
    </v-row>
    <v-row v-else>
      <v-col
        cols="12"
        md="6"
        lg="4"
        xl="3"
        v-for="(item, index) in myProcedures"
        :key="index"
        class="d-flex flex-column"
      >
        <my-tramit-card
          :clickCard="() => onPressCard(item)"
          :folioText="item.folio"
          tramitDate="item.expires_at"
          :tramitStatus="item.status_service"
          :tramitName="item.procedure.name"
        ></my-tramit-card>
      </v-col>
    </v-row>
  </v-container>
</template> 
<style scoped>
  .primary-pattern {
    height: 60vh;
    background-color: #d0d0d0;
    background-repeat: repeat;
  }
</style>
<script>
  import MyTramitCard from "../components/MyPersonalTramit.vue";
  import { mapState } from "vuex";
  export default {
    components: { MyTramitCard },
    mounted() {
      this.$store.dispatch("user/setMyProcedureList").then(() => {});
      
    },
    data: () => ({}),
    methods: {
      onPressCard(item) {
        let params = {
          id: item.id,
        };
        this.$store.dispatch("user/setProcedureAdminReview", false); 
        this.$store
          .dispatch("user/getPersonalProcedureDetail", params)
          .then(() => {
            this.$router.push("/my-tramit-detail");
          });
      },
    },
    computed: {
      ...mapState({
        myProcedures: (state) => state.user.myProcedures,
        isLoading: (state) => state.user.isLoading,
      }),
    },
  };
</script>
