<template>
  <v-container
    fluid
    class="grey lighten-3 d-flex justify-center align-center"
    style="height: 100%"
  >
    <v-row align="center" justify="center">
      <v-col cols="12">
        <h2 class="primary--text mb-8 text-center">
          Bienvenido al sistema de oficialia de partes del municipio
        </h2>
      </v-col>
      <v-col cols="12" md="6" lg="4" xl="3">
        <v-card
          flat
          class="py-12 ma-3 ma-md-6"
          @click="$router.push('/registrar-documento')"
        >
          <v-card-text class="d-flex justify-center align-center flex-column">
            <v-img
              height="20vh"
              width="40vw"
              contain
              src="/imagenes/solicitud.svg"
            ></v-img>
            <h2 class="primary--text mt-8 text-center">
              Registrar mi documento
            </h2>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="6" lg="4" xl="3">
        <v-card
          flat
          class="py-12 ma-3 ma-md-6"
          @click="$router.push('/consultar-expediente')"
        >
          <v-card-text class="d-flex justify-center align-center flex-column">
            <v-img
              height="20vh"
              width="40vw"
              contain
              src="/imagenes/revisar.svg"
            ></v-img>
            <h2 class="primary--text mt-8 text-center">Consultar mi folio</h2>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<style scoped>
.v-card {
  border-radius: 30px;
  box-shadow: 15px 15px 30px #bebebe, -15px -15px 30px #ffffff;
  transition: 0.2s ease-in-out;
  border: rgb(250, 250, 250) 0.2em solid;
}
.v-card:hover {
  cursor: pointer;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
  transform: scale(1.05);
  border: #9b2040 0.2em solid;
}
</style>
<script>
export default {
  name: "Oficialia",
  data() {
    return {
      // Variables
    };
  },
  methods: {
    // Metodos
  },
  computed: {
    // Computed
  },
  mounted() {
    // Mounted
  },
};
</script>