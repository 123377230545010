<template>
  <v-container fluid class="pt-0">
    <v-row>
      <v-img
        :height="$vuetify.breakpoint.smAndUp ? '60vh' : '100vh'"
        src="/imagenes/background.jpeg"
        class="image"
      >
        <v-row
          justify="center"
          align="center"
          class="flex-column"
          style="height: 100%"
        >
          <v-col cols="11" md="7" lg="5" class="text-center">
            <v-img
              contain
              :height="$vuetify.breakpoint.mdAndUp ? '100' : '80'"
              class="my-6"
              style="filter: brightness(0) invert(1)"
              src="/imagenes/logo_bco.png"
            ></v-img>
            <search-bar
              showSearch
              :search="searchValue"
              v-on:searchEmit="updateSearch"
              :action="onPress"
            />
            <v-btn
              color="white"
              outlined
              @click="onPress"
              rounded
              large
              class="mt-7"
            >
              Buscar trámite
            </v-btn>
          </v-col>
        </v-row>
      </v-img>
    </v-row>
    <v-row class="py-12 primary" align="center" justify="center">
      <v-col cols="12" md="9" lg="6">
        <h2 class="white--text text-center">
          Bienvenido al Sistema Municipal de Trámites y Servicios
        </h2>
        <h3 class="font-weight-regular mt-4 text-center white--text">
          Aquí encontrarás todos los trámites de las dependencias del <br />
          H XLII Ayuntamiento de Tepic
        </h3>
      </v-col>
    </v-row>
    <v-row class="py-6 py-md-12 px-auto px-lg-12">
      <v-col cols="12">
        <h2 class="primary--text mb-3 text-center">Trámites y servicios</h2>
        <h3 class="grey--text text-center">Consulta por área o dirección</h3>
        <v-row justify="center" align="center" class="py-12">
          <v-col
            cols="12"
            sm="6"
            md="4"
            xl="3"
            v-for="(item, n) in dependencias"
            :key="n"
          >
            <dependency-card
              :imgsrc="item.bgimage"
              :imgDep="item.icon"
              :titleText="item.name"
              :clickCard="
                () => {
                  onPressCard(item);
                }
              "
            ></dependency-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-footer padless absolute>
      <v-col class="primary py-4 text-center white--text" cols="12">
        <strong>Copyright © 2021 H. XLII Ayuntamiento de Tepic</strong>
        <br />
        <strong>Dirección de Innovación</strong>
      </v-col>
    </v-footer>
  </v-container>
</template>
<style scoped>
.initial {
  height: 40vh;
  z-index: 1;
}
.login-text {
  position: absolute;
  top: 10px;
  right: 20px;
  bottom: 0px;
  left: 0px;
  justify-content: flex-end;
}
.transparent-back {
  background: rgba(255, 255, 255, 0.3);
}
.image::before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.4);
}
</style>
<script>
import SearchBar from "../components/SearchBar.vue";
import DependencyCard from "../components/DependencyCard.vue";
import { mapState } from "vuex";
export default {
  components: { SearchBar, DependencyCard },
  mounted() {
    this.$store.dispatch("procedures/getDependencyList").then(() => {});
  },
  data: () => ({
    search: "",
    dependencias: [
      {
        id: 1,
        name: "REGISTRO CIVIL",
        bgimage: "/imagenes/dependencias/Registro civil.jpg",
        icon: "/imagenes/dependencias/registro.svg",
      },
      {
        id: 2,
        name: "DEPARTAMENTO DE PANTEONES",
        bgimage: "/imagenes/dependencias/panteones.jpg",
        icon: "/imagenes/dependencias/tepic.svg",
      },
      {
        id: 3,
        name: "DIRECCIÓN GENERAL DE DESARROLLO URBANO Y ECOLOGÍA",
        bgimage: "/imagenes/background.jpeg",
        icon: "/imagenes/dependencias/desarrollo.svg",
      },
      {
        id: 4,
        name: "LICENCIAS DE FUNCIONAMIENTO DE NEGOCIOS",
        bgimage: "/imagenes/dependencias/Turismo.jpg",
        icon: "/imagenes/dependencias/tepic.svg",
      },
      {
        id: 5,
        name: "DIRECCIÓN DE PARQUES Y JARDINES",
        bgimage: "/imagenes/dependencias/jardines.jpg",
        icon: "/imagenes/dependencias/tepic.svg",
      },
      {
        id: 6,
        name: "DIRECCIÓN GENERAL DE SEGURIDAD PÚBLICA Y VIALIDAD",
        bgimage: "/imagenes/dependencias/Seguridad publica.jpg",
        icon: "/imagenes/dependencias/seguridad.svg",
      },
      {
        id: 7,
        name: " DIRECCIÓN DE DESARROLLO ECONÓMICO Y TURISMO",
        bgimage: "/imagenes/dependencias/Turismo.jpg",
        icon: "/imagenes/dependencias/tepic.svg",
      },
      {
        id: 8,
        name: "DEPARTAMENTO DE SERVICIOS DE MERCADOS PÚBLICOS",
        bgimage: "/imagenes/dependencias/mercado.jpg",
        icon: "/imagenes/dependencias/tepic.svg",
      },
    ],
  }),
  methods: {
    onPressCard(item) {
      this.$store.dispatch("procedures/setSearch", item.name);
      this.$router.push("/tramites");
    },
    onPress() {
      this.$router.push("/tramites");
    },
    updateSearch(text) {
      this.$store.dispatch("procedures/setSearch", text);
    },
  },
  computed: {
    ...mapState({
      userFlag: (state) => state.user.isUserLogued,
      searchValue: (state) => state.procedures.search,
      dependecieList: (state) => state.procedures.dependecyList,
      userToken: (state) => state.user.userToken,
      userLogued: (state) => state.user.loguedUser,
    }),
  },
};
</script>
