<template>
  <v-container class="gray py-0" fluid style="height: 100%">
    <v-row align="center" justify="center" style="height: 100%">
      <v-col
        cols="12"
        md="4"
        class="white align-center justify-center d-flex elevation-4"
        :style="{ height: $vuetify.breakpoint.mdAndUp ? '100%' : '' }"
      >
        <v-card elevation="0">
          <v-card-text>
            <v-row align="center" justify="center">
              <v-col cols="12">
                <h3 class="primary--text text-center">Busca tu expediente</h3>
              </v-col>
              <v-col cols="12">
                <v-form ref="form" v-model="valid" @submit.prevent>
                  <v-text-field
                    label="Número de expediente"
                    outlined
                    :rules="rule"
                    v-model="folio_expedient"
                    prepend-inner-icon="mdi-magnify"
                  ></v-text-field>

                  <v-text-field
                    label="Código de seguridad"
                    outlined
                    :rules="rule"
                    v-model="secure_code"
                    prepend-inner-icon="mdi-magnify"
                  ></v-text-field>
                  <v-btn
                    class="mt-4"
                    block
                    rounded
                    large
                    color="primary"
                    dark
                    @click="validateInputs"
                    :loading="isLoading"
                    type="submit"
                    :disabled="isLoading"
                  >
                    <v-icon left>mdi-magnify</v-icon>
                    Buscar
                  </v-btn>
                </v-form>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col
        cols="12"
        md="8"
        v-if="isLoading"
        class="d-flex justify-center align-center flex-column py-12"
      >
        <v-progress-circular
          indeterminate
          size="40"
          color="primary"
        ></v-progress-circular>
        <p class="text-center">Buscando expedientes relacionados</p>
      </v-col>
      <v-col cols="12" md="8" class="py-12" v-if="isInitial">
        <v-img
          height="150"
          contain
          src="/imagenes/empty_results.svg"
          class="align-self-center"
        ></v-img>
        <p class="text-center mt-6 mt-md-12">
          Ingresa el número de expediente y el código de seguridad <br />
          para consultar el expediente de tu documento.
        </p>
      </v-col>
      <v-col
        cols="12"
        md="8"
        class="py-12"
        v-else-if="!isLoading && Object.keys(documentSelected).length == 0"
      >
        <v-img
          height="150"
          contain
          src="/imagenes/no_results.svg"
          class="align-self-center"
        ></v-img>
        <p class="text-center mt-6 mt-md-12">
          No se encontraron resultados para tu búsqueda. <br />
          Por favor, intenta con otros términos.
        </p>
      </v-col>
      <v-col
        cols="12"
        md="8"
        class="py-12 px-3 px-md-8"
        v-else-if="!isLoading && Object.keys(documentSelected).length > 0"
      >
        <document-card></document-card>
        <v-card outlined class="mt-6">
          <v-card-title class="info darken-3">
            <v-row>
              <v-col cols="12" md="8">
                <h5 class="white--text">Seguimiento del expediente</h5>
              </v-col>
            </v-row>
          </v-card-title>

          <v-card-text v-if="documentSelected.follows.length > 0">
            <v-data-table
              :headers="headers"
              :items="documentSelected.follows"
              :items-per-page="20"
              no-data-text="No hay actualizaciones registradas para este expediente"
            >
              <template v-slot:item.created_at="{ item }">
                <span>{{ item.created_at | date }}</span>
              </template>
              <template v-slot:item.user="{ item }">
                <span>{{ item.user.name + " " + item.user.lastname }}</span>
              </template>
              <template v-slot:item.actions="{ item }">
                <v-btn
                  v-if="!!item.file"
                  small
                  rounded
                  depressed
                  outlined
                  color="primary"
                  @click="seeFile(item.file)"
                >
                  <v-icon left>mdi-arrow-down</v-icon>
                  Ver evidencia
                </v-btn>
                <p class="mb-0 font-italic" v-else>Sin documento de respaldo</p>
              </template>
            </v-data-table>
          </v-card-text>
          <v-card-text v-else>
            <v-row>
              <v-col
                cols="12"
                class="d-flex justify-center align-center flex-column py-12"
              >
                <v-icon color="grey lighten-1" size="90" class="mb-6">
                  mdi-file-document-outline
                </v-icon>
                <h3 class="font-weight-regular grey--text text--lighten-1">
                  No hay actualizaciones registradas para este expediente
                </h3>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { mapState } from "vuex";
import DocumentCard from "../../components/oficialia/DocumentCard.vue";
export default {
  name: "ConsultExpedient",
  components: {
    DocumentCard,
  },
  data: () => ({
    folio_expedient: "",
    secure_code: "",
    isLocated: "",
    rule: [(v) => !!v || "Este campo es requerido"],
    valid: false,
    isInitial: true,
    headers: [
      {
        text: "Fecha",
        align: "start",
        sortable: false,
        value: "created_at",
      },
      { text: "Descripción", value: "comment" },
      { text: "Usuario", value: "user" },
      { text: "Acciones", value: "actions", sortable: false },
    ],
  }),
  methods: {
    validateInputs() {
      this.$refs.form.validate();
      if (this.valid) {
        this.searchExpedient();
      }
    },
    searchExpedient() {
      this.$store
        .dispatch("oficialia/getDocument", {
          folio_expedient: this.folio_expedient,
          secure_code: this.secure_code,
        })
        .then(() => {
          this.isInitial = false;
        });
    },
    seeFile(file) {
      window.open(this.server + file, "_blank");
    },
  },
  computed: {
    ...mapState({
      documentSelected: (state) => state.oficialia.documentSelected,
      isLoading: (state) => state.oficialia.isLoading,
      server: (state) => state.user.server,
    }),
  },
};
</script>