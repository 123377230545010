 <template>
   <v-card @click="clickCard" flat color="transparent">
     <v-img
       height="200"
       cover
       :src="imgsrc"
       style="border-radius: 0.5rem"
       class="image"
     >
       <v-row align="center" justify="center" style="height: 100%; z-index: 4">
         <v-col
           class="flex d-flex justify-center align-center flex-column"
           cols="10"
         >
         <v-img
           height="60"
           contain
           :src="imgDep"
           class="mb-3"
           style="z-index: 4"
         >
         </v-img>
         <h4
           class="font-weight-medium white--text text-center"
           style="z-index: 4"
         >
         {{ titleText }}
         </h4>
        </v-col>
      </v-row>
    </v-img>
  </v-card>
</template>
<style scoped>
  .image::before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.4);
  }
</style>
<script>
  export default {
    props: {
      titleText: {
        type: String,
        required: true,
        default: "",
      },
      imgsrc: {
        type: String,
        required: true,
        default: "https://picsum.photos/id/11/400/200",
      },
      clickCard: {
        type: Function,
      },
      imgDep: {
        type: String,
        required: true,
        default: "https://picsum.photos/id/11/400/200",
      },
    },
  };
</script>