<template>
  <v-row>
    <v-col cols="12">
      <v-card outlined>
        <v-card-title class="">
          <v-img src="/imagenes/review.svg" height="200" contain></v-img>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-alert type="info" class="mb-2">
                Valida tu información antes de enviar el documento
              </v-alert>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <h3 class="primary--text">Información del documento</h3>
            </v-col>
            <v-col cols="12">
              <p class="mb-1">Tipo de documento</p>
              <v-chip color="info" label small class="text-capitalize mb-4">
                {{ document_type }}
              </v-chip>
              <p class="mb-1">Asunto</p>
              <p class="mb-2 font-weight-bold" v-text="asunto"></p>
              <p class="mb-1">Folios</p>
              <p class="mb-2 font-weight-bold" v-text="folios"></p>
              <p class="mb-1">Archivos</p>
              <p class="mb-2 font-weight-bold">
                {{ archivos }}
              </p>
            </v-col>
            <v-col cols="12">
              <h3 class="primary--text">Información del remitente</h3>
            </v-col>
            <v-col cols="12">
              <p class="mb-1">Tipo de persona</p>
              <v-chip
                color="info"
                label
                outlined
                small
                class="text-capitalize mb-4"
              >
                {{ person_type }}
              </v-chip>
              <p class="mb-1">
                {{ person_type == "fisica" ? "Nombre" : "Razón social" }}
              </p>
              <p class="mb-2 font-weight-bold" v-text="name"></p>
              <p class="mb-1">
                {{ person_type == "fisica" ? "CURP" : "RFC" }}
              </p>
              <p class="mb-2 font-weight-bold" v-text="rfc"></p>
              <p class="mb-1">Correo electrónico</p>
              <p class="mb-2 font-weight-bold" v-text="email"></p>
              <p v-if="phone != ''" class="mb-1">Teléfono</p>
              <p v-if="phone != ''" class="mb-2 font-weight-bold">
                {{ phone | phone }}
              </p>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
export default {
  //estructura base de vue
  name: "ResumenCard",
  //props
  props: {
    document_type: {
      type: String,
      default: "Carta",
    },
    asunto: {
      type: String,
      default: "",
    },
    folios: {
      type: String,
      default: "",
    },
    archivos: {
      type: String,
      default: "",
    },
    person_type: {
      type: String,
      default: "fisica",
    },
    name: {
      type: String,
      default: "",
    },
    rfc: {
      type: String,
      default: "",
    },
    email: {
      type: String,
      default: "",
    },
    phone: {
      type: String,
      default: "",
    },
  },
};
</script>

<style>
</style>