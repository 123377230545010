<template>
  <v-container fluid class="gray login-container">
    <v-row align="center" justify="center" class="login-container">
      <v-col cols="12" sm="10" md="8" lg="5" xl="3">
        <v-card elevation="6" style="border-radius: 0.5rem">
          <v-card-title class="py-4 primary">
            <v-btn depressed text @click="onClickImage" color="white" large>
              <v-icon left>mdi-arrow-left</v-icon>
              Regresar
            </v-btn>
          </v-card-title>
          <v-card-text>
            <v-row>
            <v-col cols="12" align="center" justify="center" class="backgroundGray">
                    <h4 class="text--center createAccount my-3" >Olvidé mi contraseña</h4>
                    <h3 class="text--center seconText" >Completa la siguiente información para restablecer tu contraseña. <br>
                    Recibirás un correo electrónico con un enlace para restablecer.</h3>
            </v-col>  
              <v-col cols="12">
                <v-text-field
                   label="Correo electrónico*"
                  rounded
                  outlined
                  hide-details
                  type="email"
                  required 
                  autocomplete="email"
                  v-model="mail"
                  autofocus
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <Button
                  color="primary"
                  :action="onPressForgot"
                  text="Restablecer contraseña"
                />
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template> 
<script>
  import Button from "../components/Button.vue";
  export default {
    components: { Button },
    data: () => ({
      publicPath: process.env.BASE_URL,
      mail: "",
    }),
    methods: {
      onClickImage() {
        this.$router.go(-1);
      },
      onPressForgot() {
          if(this.mail == ""){
              this.$store.dispatch("alerts/setSnackbarText", "Correo vacío, ingrese su correo");
              this.$store.dispatch("alerts/setSnackbarColor", "error");
              this.$store.dispatch("alerts/setSnackbar", true);        
          }
          else{
            let params ={
              email: this.mail
            }
            this.$store.dispatch("user/resetPassword", params).then(() => {
                this.$store.dispatch("alerts/setSnackbarText", "Correo electrónico enviado correctamente. Revisa tu bandeja de entrada.");
                this.$store.dispatch("alerts/setSnackbarColor", "success");
                this.$store.dispatch("alerts/setSnackbar", true);                      
            });
          }
      },
    },
  };
</script>
<style scoped>
  .login-container {
    height: 100%;
  }
  .createAccount{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 23px;
    color: #9B2040;    
  }
  .seconText{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 23px;
    color: #222831;    
  }
</style>
