<template>
  <v-dialog
    v-model="showModal"
    :max-width="$vuetify.breakpoint.smAndDown ? '100vw' : '30vw'"
    persistent
  >
    <v-card>
      <v-card-text>
        <v-container>
          <v-row align="center" justify="center">
            <v-col cols="12">
              <div class="d-flex justify-center align-center flex-column">
                <v-icon left color="primary" size="80">
                  mdi-progress-pencil
                </v-icon>
                <h3 class="primary--text text-center mt-5 mb-3">
                  Actualizar seguimiento del expediente
                </h3>
              </div>
            </v-col>
            <v-col cols="12">
              <v-textarea
                v-model="comment"
                :rules="rules"
                label="Comentario"
                outlined
                rows="3"
                placeholder="Escribe un comentario"
              ></v-textarea>
            </v-col>
            <v-col cols="12">
              <v-file-input
                v-model="file"
                label="Archivo"
                outlined
                placeholder="Selecciona un archivo"
                @change="selectFile"
                show-size
                small-chips
              ></v-file-input>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions class="justify-center">
        <v-row class="mx-0">
          <v-col cols="12" md="6" order="2" order-md="1">
            <v-btn color="primary" rounded block large text @click="cancel">
              Cancelar
            </v-btn>
          </v-col>
          <v-col cols="12" md="6" order="1" order-md="2">
            <v-btn color="primary" rounded block large @click="validateComment">
              Actualizar seguimiento
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  props: {
    cancel: {
      type: Function,
      required: true,
    },
    showModal: {
      type: Boolean,
      required: true,
    },
  },
  data: () => ({
    comment: "",
    file: [],
    currentFile: undefined,
    rules: [(v) => !!v || "Este campo es requerido"],
  }),
  methods: {
    validateComment() {
      if (this.comment.length > 0) {
        this.updateStatus();
      }
      return false;
    },
    updateStatus() {
      let payload = {
        comment: this.comment,
      };
      if (this.currentFile) {
        payload.file = this.currentFile;
      }

      this.$store
        .dispatch("oficialia/updateDocumentRecord", payload)
        .then(() => {
          this.comment = "";
          this.file = [];
          this.currentFile = undefined;
          this.cancel();
        });
    },
    selectFile(file) {
      this.currentFile = file;
    },
  },
};
</script>
