<template>
  <v-text-field
    solo
    flat
    hide-details
    prepend-inner-icon="mdi-magnify"
    placeholder="Buscar trámite o servicio"
    v-model="search"
    rounded
    v-on:keyup.enter="action"
    clearable
  ></v-text-field>
</template>
<script>
  export default {
    props: {
      showSearch: {
        type: Boolean,
        default: true,
      },
      action: {
        type: Function,
      },
    },
    data: () => ({
      search: "",
    }),
    methods: {
    
    },
    computed: {
      lgComputed() {
        let cols = 12;
        let active = 0;
        if (this.showSearch) {
          active++;
        }
        return cols / active;
      },
    },
    watch: {
      search: function (value) {
        this.$emit("searchEmit", value);
      },
    },
  };
</script>