<template>
    <v-row v-if="isLoading">
      <v-col cols="12" class="align-center justify-center d-flex py-12">
        <v-progress-circular
          size="40"
          color="primary"
          indeterminate
        ></v-progress-circular>
      </v-col>
    </v-row>
    <v-row v-else>
      <v-col cols="12">
        <admin-data-table :data="adminData"></admin-data-table>
      </v-col>
    </v-row>
</template>
<script>
import AdminDataTable from "../components/AdminDataTable.vue";
import { mapState } from "vuex";

export default {
components: { AdminDataTable },
  mounted() {
    this.$store.dispatch("user/getAdminData2").then(() => {}).catch(() => {
        this.$router.push("/login"); 
        });
  },   
  data: () => ({ }),
    computed: {
    ...mapState({
      adminData: (state) => state.user.adminData,
      isLoading: (state) => state.user.isLoading,
    }),
  },  
};
</script>