<template>
  <div>
    <v-list-item v-if="userFlag == false" to="/ingresar">
      <v-list-item-content>
        <v-list-item-title class="black--text">
          Iniciar sesión
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <v-list-group
      v-else-if="
        userLogued.role == 'Administrador' ||
        userLogued.role == 'Super Administrador'
      "
    >
      <template v-slot:activator>
        <v-list-item-content>
          <v-list-item-title>Administrar</v-list-item-title>
        </v-list-item-content>
      </template>

      <v-list light>
        <v-list-item
          @click="goToAdminUser"
          v-if="userLogued.role == 'Super Administrador'"
        >
          <v-list-item-title class="black--text">
            Administrar usuarios
          </v-list-item-title>
        </v-list-item>
        <v-list-item
          @click="goToAdministrarTramites"
          v-if="userLogued.role == 'Administrador'"
        >
          <v-list-item-title> Administrar trámites </v-list-item-title>
        </v-list-item>
        <v-divider></v-divider>
        <v-list-item
          @click="goToAdminTramits"
          v-if="userLogued.role == 'Administrador'"
        >
          <v-list-item-title> Trámites pendientes </v-list-item-title>
        </v-list-item>
        <v-divider></v-divider>
        <v-list-item
          @click="goToAdminTramitsArchives"
          v-if="userLogued.role == 'Administrador'"
        >
          <v-list-item-title class="black--text">
            Trámites archivados
          </v-list-item-title>
        </v-list-item>
        <v-divider></v-divider>
        <v-list-item to="/documentos" v-if="userLogued.role == 'Administrador'">
          <v-list-item-title class="black--text">
            Oficilia de partes
          </v-list-item-title>
        </v-list-item>
        <v-divider></v-divider>
        <v-list-item @click="logOut">
          <v-list-item-title class="error--text">
            Cerrar sesión
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-list-group>
    <v-list-group v-else>
      <template v-slot:activator>
        <v-list-item-content>
          <v-list-item-title> Administrar </v-list-item-title>
        </v-list-item-content>
      </template>
      <v-list color="white">
        <v-list-item @click="goToMyTramits">
          <v-list-item-title class="black--text">
            Mis trámites
          </v-list-item-title>
        </v-list-item>
        <v-list-item @click="logOut">
          <v-list-item-title class="error--text">
            Cerrar sesión
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-list-group>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  data: () => ({}),
  methods: {
    updateSearch(text) {
      this.$store.dispatch("procedures/setSearch", text);
    },
    onPress() {
      this.$router.push("/tramites");
    },
    goToMyTramits() {
      this.$router.push("/mis-tramites");
    },
    logOut() {
      this.$store.dispatch("user/logout").then(() => {
        let miStorage = window.localStorage;
        miStorage.removeItem("usr_data");
        miStorage.removeItem("usr_acctok");
        miStorage.removeItem("usr_tok_expires_at");
      });
    },
    goToAdminTramits() {
      this.$router.push("/admin");
    },
    goToAdministrarTramites() {
      this.$router.push("/admin-tramites");
    },
    goToAdminUser() {
      this.$router.push("/users");
    },
    goToAdminTramitsArchives() {
      this.$router.push("/tramites-archives");
    },
  },
  computed: {
    ...mapState({
      userFlag: (state) => state.user.isUserLogued,
      searchValue: (state) => state.procedures.search,
      dependecieList: (state) => state.procedures.dependecyList,
      userToken: (state) => state.user.userToken,
      userLogued: (state) => state.user.loguedUser,
    }),
  },
};
</script>