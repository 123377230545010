<template>
  <v-container fluid class="gray login-container">
    <v-row align="center" justify="center">
      <v-col cols="12" sm="10" md="8" lg="5" xl="3">
        <v-card elevation="6" style="border-radius: 0.5rem">
          <v-card-title class="py-4 primary">
            <v-btn depressed text @click="onClickImage" color="white" large>
              <v-icon left>mdi-arrow-left</v-icon>
              Regresar
            </v-btn>
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <v-img
                  src="imagenes/logo_bco.png"
                  class="my-5 my-md-8"
                  :height="$vuetify.breakpoint.mdAndUp ? '100' : '80'"
                  contain
                ></v-img>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  label="CURP o correo electrónico*"
                  rounded
                  outlined
                  hide-details
                  type="email"
                  required
                  autocomplete="email"
                  v-model="mail"
                  autofocus
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  label="Contraseña*"
                  v-model="password"
                  rounded
                  outlined
                  hide-details
                  v-on:keyup.enter="onPressLogin"
                  :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="show1 ? 'text' : 'password'"
                  required
                  autocomplete="current-password"
                  @click:append="show1 = !show1"
                />
              </v-col>
              <v-col cols="12">
                <Button
                  color="primary"
                  :action="onPressLogin"
                  text="Iniciar sesión"
                  :isLoading="loadingLogin"
                />
              </v-col>
              <v-col cols="12">
                <Button
                  color="primary"
                  :action="onPressRegister"
                  text="Crear mi cuenta"
                  whiteButton
                />
              </v-col>
              <v-col cols="12">
                <Button
                  color="primary"
                  :action="onPressForgot"
                  text="Olvidé mi contraseña"
                  textButton
                />
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import Button from "../components/Button.vue";
export default {
  components: { Button },
  data: () => ({
    publicPath: process.env.BASE_URL,
    mail: "",
    password: "",
    show1: false,
    loadingLogin: false,
  }),
  methods: {
    onClickImage() {
      this.$router.go(-1);
    },
    onPressLogin() {
      if (
        this.mail == "" ||
        this.mail == null ||
        this.password == "" ||
        this.password == null
      ) {
        this.$store.dispatch(
          "alerts/setSnackbarText",
          "Ingrese CURP o correo electrónico y contraseña"
        );
        this.$store.dispatch("alerts/setSnackbarColor", "warning");
        this.$store.dispatch("alerts/setSnackbar", true);
        return;
      }
      this.loadingLogin = true;
      let user = {
        curp: this.mail,
        password: this.password,
      };
      this.$store
        .dispatch("user/login", user)
        .then((data) => {
          let miStorage = window.localStorage;
          miStorage.setItem("usr_data", JSON.stringify(data.user));
          miStorage.setItem("usr_acctok", data.token.accessToken);
          miStorage.setItem("usr_tok_expires_at", data.token.token.expires_at);

          this.loadingLogin = false;
          this.$router.go(-1);
        })
        .catch(() => {
          this.$store.dispatch(
            "alerts/setSnackbarText",
            "Usuario o contraseña incorrectos"
          );
          this.$store.dispatch("alerts/setSnackbarColor", "error");
          this.$store.dispatch("alerts/setSnackbar", true);
          this.loadingLogin = false;
        });
    },
    onPressRegister() {
      this.$router.push("/registrar");
    },
    onPressForgot() {
      this.$router.push("/recuperar-contrasena");
    },
  },
};
</script>
<style scoped>
.login-container {
  height: 100%;
}
</style>
