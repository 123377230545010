<template>
  <v-container fluid>
    <v-card>
      <v-card-title>
        <v-row class="mx-0">
          <v-col cols="12" md="8">
            <p class="mb-0 primary--text">
              <b> Oficialia de partes </b>
            </p>
            <p class="mb-0">
              <small>{{ documents.length }} expedientes recibidos</small>
            </p>
          </v-col>
          <v-col cols="12" md="4" class="d-flex justify-end align-center">
            <v-btn to="/registrar-documento" large rounded color="primary" dark>
              Registrar un documento
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-row class="mx-0">
          <v-col cols="12" md="7">
            <v-text-field
              v-model="search"
              label="Buscar por expediente"
              outlined
              dense
              clearable
              class="my-2"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="5">
            <v-select
              v-model="status"
              :items="status_types"
              label="Filtrar por estatus"
              outlined
              dense
              class="my-2"
            ></v-select>
          </v-col>
        </v-row>
        <v-data-table
          cols="12"
          no-data-text="No hay usuarios registrados"
          :headers="headers"
          :items="dataFiltered"
          class="mytable"
          :items-per-page="20"
          :loading="isLoading"
          loading-text="Cargando expedientes"
          d-initial-sort
          item-key="id"
        >
          <template v-slot:item.type="{ item }" class="text-xs-center">
            <v-chip
              color="info"
              label
              outlined
              class="font-weight-medium"
              small
            >
              {{ item.type }}
            </v-chip>
          </template>
          <template v-slot:item.status="{ item }" class="text-xs-center">
            <v-chip
              :color="getColor(item.status)"
              label
              class="font-weight-medium"
              small
            >
              {{ item.status }}
            </v-chip>
          </template>
          <template v-slot:item.created_at="{ item }" class="text-xs-center">
            {{ item.created_at | date }}
          </template>
          <template v-slot:item.actions="{ item }" class="text-xs-center">
            <v-menu offset-y>
              <template v-slot:activator="{ on }">
                <v-btn outlined color="primary" rounded v-on="on">
                  Opciones
                  <v-icon right>mdi-chevron-down</v-icon>
                </v-btn>
              </template>
              <v-list color="white">
                <v-list-item @click="goToDocumentDetails(item)">
                  <v-icon color="black" left>mdi-eye-outline</v-icon>
                  <v-list-item-title class="black--text">
                    Ver expediente
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { mapState } from "vuex";

export default {
  mounted() {
    this.getDocuments();
  },
  data: () => ({
    showModalCrearTramite: false,
    deleteDialog: false,
    search: "",
    status: "",
    status_types: [
      {
        text: "Todos",
        value: "",
      },
      {
        text: "En proceso",
        value: "En proceso",
      },
      {
        text: "Atendido",
        value: "Atendido",
      },
      {
        text: "Pendiente",
        value: "Pendiente",
      },
      {
        text: "Denegado",
        value: "Denegado",
      },
    ],
    infoDialog: false,
    headers: [
      {
        text: "# de expediente",
        sortable: true,
        align: "center",
        value: "folio_expedient",
        class: "black--text",
      },
      {
        text: "Nombre",
        sortable: true,
        align: "center",
        value: "social_reason",
        class: "black--text",
      },
      {
        text: "Asunto",
        align: "center",
        sortable: true,
        value: "subject",
        class: "black--text",
      },
      {
        text: "Tipo",
        align: "center",
        sortable: true,
        value: "type",
        class: "black--text",
      },
      {
        text: "Estado",
        sortable: true,
        align: "center",
        value: "status",
        class: "black--text",
      },
      {
        text: "Fecha de recepción",
        sortable: true,
        align: "center",
        value: "created_at",
        class: "black--text",
      },
      {
        text: "Acciones",
        sortable: false,
        align: "center",
        value: "actions",
        class: "black--text",
      },
    ],
  }),
  methods: {
    getDocuments() {
      this.$store.dispatch("oficialia/setIsLoading", true);
      this.$store.dispatch("oficialia/getDocumentList");
    },
    goToDocumentDetails(item) {
      this.$store
        .dispatch("oficialia/getDocument", {
          folio_expedient: item.folio_expedient,
          secure_code: item.secure_code,
        })
        .then(() => {
          this.$router.push("/detalle-documento");
        });
    },
    getColor(status) {
      switch (status) {
        case "En proceso":
          return "primary";
        case "Atendido":
          return "success";
        case "Pendiente":
          return "warning";
        case "Denegado":
          return "error";
        default:
          return "info";
      }
    },
  },
  computed: {
    ...mapState({
      loguedUser: (state) => state.user.loguedUser,
      documents: (state) => state.oficialia.documents,
      isLoading: (state) => state.oficialia.isLoading,
    }),
    dataFiltered() {
      return this.documents.filter((item) => {
        return (
          item.status.toLowerCase().includes(this.status.toLowerCase()) &&
          (item.subject.toLowerCase().includes(this.search.toLowerCase()) ||
            item.folio_expedient
              .toString()
              .includes(this.search.toLowerCase()) ||
            item.social_reason
              .toLowerCase()
              .includes(this.search.toLowerCase()))
        );
      });
    },
  },
};
</script>
