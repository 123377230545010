<template>
  <v-card elevation="2" @click="clickCard" class="d-flex flex flex-column">
    <v-card-text class="flex pb-0">
      <v-row align="flex-end">
        <v-col cols="12" class="text-right py-0">
          <v-chip label color="primary lighten-3" small>
            Folio {{ folioText }}
          </v-chip>
        </v-col>
        <v-col cols="12" class="text-left">
          <p class="mb-0">Trámite</p>
          <h4 class="text--center darkgray--text">
            {{ tramitName }}
          </h4>
          <br />
          <p class="mb-0">Etapa de tu trámite</p>
          <h4 class="text--center primary--text">
            {{ tramitStatus }}
          </h4>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>
<style>
  .v-card {
    border-radius: 0.5rem;
  }
</style>
<script>
  export default {
    props: {
      folioText: {
        type: String,
        required: true,
        default: "",
      },
      tramitName: {
        type: String,
        required: true,
        default: "",
      },
      tramitStatus: {
        type: String,
        required: true,
        default: "",
      },
      tramitDate: {
        type: String,
        required: true,
        default: "",
      },
      clickCard: {
        type: Function,
      },
    },
  };
</script>