<template>
  <v-container fluid>
    <v-card>
      <v-card-title>
        <v-row class="mx-0">
          <v-col cols="12" md="8">
            <p class="mb-0 primary--text">
              <b> Usuarios </b>
            </p>
            <p class="mb-0">
              <small>{{ users.length }} resultados</small>
            </p>
          </v-col>
          <v-col cols="12" md="4" class="d-flex justify-end align-center">
            <v-btn @click="goToNewUser()" large rounded color="primary" dark>
              Crear un usuario
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-data-table
          cols="12"
          no-data-text="No hay usuarios registrados"
          :headers="headers"
          :items="users"
          class="mytable"
          :items-per-page="20"
          d-initial-sort
          item-key="id"
        >
          <template v-slot:item.name="{ item }" class="text-xs-center">
            {{ item.name + " " + item.lastname }}
          </template>
          <template v-slot:item.actions="{ item }" class="text-xs-center">
            <v-menu offset-y>
              <template v-slot:activator="{ on }">
                <v-btn outlined color="primary" rounded v-on="on">
                  Opciones
                  <v-icon right>mdi-chevron-down</v-icon>
                </v-btn>
              </template>
              <v-list color="white">
                <v-list-item @click="openViewUserInfo(item)">
                  <v-icon color="black" left>mdi-pencil-circle-outline</v-icon>
                  <v-list-item-title class="black--text">
                    Editar usuario
                  </v-list-item-title>
                </v-list-item>
                <v-divider></v-divider>
                <v-list-item @click="openDialogDelete(item)">
                  <v-icon color="black" left>mdi-delete-outline</v-icon>
                  <v-list-item-title class="black--text">
                    Eliminar usuario
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>

    <v-dialog
      v-model="deleteDialog"
      :max-width="$vuetify.breakpoint.smAndDown ? '60vw' : '30vw'"
      persistent
    >
      <v-card :tile="$vuetify.breakpoint.smAndDown">
        <v-card-text>
          <div class="d-flex justify-center align-center flex-column">
            <v-icon color="error" size="80">mdi-help-circle-outline</v-icon>
            <h3 class="error--text text-center mt-5 mb-3">
              ¿Seguro que desea eliminar este usuario?
            </h3>
          </div>
          <p class="text-center">
            El usuario
            <b>{{ userToDelete.name + " " + userToDelete.lastname }}</b> perderá
            el acceso al sistema inmediatamente. Esta acción no puede revertirse
          </p>
        </v-card-text>

        <v-card-actions class="justify-center">
          <v-row class="mx-0">
            <v-col cols="12" md="6">
              <v-btn
                color="primary"
                rounded
                block
                large
                text
                @click="deleteDialog = false"
              >
                No, regresar
              </v-btn>
            </v-col>
            <v-col cols="12" md="6">
              <v-btn color="error" rounded block large @click="deleteUser()">
                Si, eliminar
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapState } from "vuex";

export default {
  mounted() {
    this.refresh();
  },
  data: () => ({
    showModalCrearTramite: false,
    deleteDialog: false,
    infoDialog: false,
    headers: [
      {
        text: "Nombre",
        sortable: true,
        align: "center",
        value: "name",
        class: "black--text",
      },
      {
        text: "Rol",
        align: "center",
        sortable: true,
        value: "role",
        class: "black--text",
      },
      {
        text: "Correo electrónico",
        sortable: true,
        align: "center",
        value: "email",
        class: "black--text",
      },
      {
        text: "CURP",
        sortable: true,
        align: "center",
        value: "curp",
        class: "black--text",
      },
      {
        text: "Acciones",
        sortable: false,
        align: "center",
        value: "actions",
        class: "black--text",
      },
    ],
    users: [],
    userData: {},
    idToDelete: "",
    userToDelete: {},
  }),
  methods: {
    openDialogDelete(item) {
      this.idToDelete = item.id;
      this.deleteDialog = true;
      this.userToDelete = item;
    },
    refresh() {
      this.$store.dispatch("user/getUserList").then((data) => {
        this.users = data;
      });
    },
    deleteUser() {
      let params = {
        id: this.idToDelete,
      };
      this.$store
        .dispatch("user/deleteUser", params)
        .then(() => {
          this.$store.dispatch(
            "alerts/setSnackbarText",
            "Usuario eliminado correctamente."
          );
          this.$store.dispatch("alerts/setSnackbarColor", "success");
          this.$store.dispatch("alerts/setSnackbar", true);
          this.refresh();
        })
        .catch(() => {
          this.$store.dispatch("alerts/setSnackbarText", "Algo salió mal.");
          this.$store.dispatch("alerts/setSnackbarColor", "error");
          this.$store.dispatch("alerts/setSnackbar", true);
        });
      this.deleteDialog = false;
    },
    openViewUserInfo(item) {
      this.$store.dispatch("user/setUserDataEditFlag", true);
      this.$store.dispatch("user/setUserDataEdit", item).then(() => {
        this.$router.push("/create-user");
      });
    },
    goToNewUser() {
      this.$store.dispatch("user/setUserDataEditFlag", false);
      this.$router.push("/create-user");
    },
  },
  computed: {
    ...mapState({
      loguedUser: (state) => state.user.loguedUser,
    }),
  },
};
</script>
