<template>
  <v-card outlined :loading="isLoading" loader-height="20">
    <v-card-title class="info darken-3">
      <v-row>
        <v-col cols="12" md="8">
          <h5 class="white--text">Detalle del expediente</h5>
          <small class="white--text">
            <small>
              <span class="font-weight-medium">Fecha de registro:</span>
              <br />
              {{ documentSelected.created_at | date }}
            </small>
          </small>
        </v-col>
        <v-col
          cols="12"
          md="4"
          class="d-flex align-end justify-center flex-column"
        >
          <v-chip small label color="white" class="font-weight-medium my-2">
            Expediente - {{ documentSelected.folio_expedient }}
          </v-chip>
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text class="pa-6">
      <v-row>
        <v-col cols="12" lg="6">
          <h3 class="primary--text">Información del documento</h3>
          <p class="mb-2">Tipo de documento</p>
          <v-chip
            color="info"
            label
            outlined
            small
            class="text-capitalize mb-4"
          >
            {{ documentSelected.type }}
          </v-chip>
          <p class="mb-2">Asunto</p>
          <p
            class="mb-4 font-weight-bold"
            v-text="documentSelected.subject"
          ></p>
          <p class="mb-2">Folios del documento</p>
          <p class="mb-4 font-weight-bold" v-text="documentSelected.folios"></p>
          <p class="mb-2">Estatus del expediente</p>
          <v-chip
            small
            label
            :color="getColor()"
            class="font-weight-medium mb-4"
          >
            {{ documentSelected.status }}
          </v-chip>
          <p class="mb-2">Archivos entregados</p>
          <v-btn rounded color="primary" depressed @click="seeFile">
            <v-icon left>mdi-arrow-down</v-icon>
            Ver documento
          </v-btn>
        </v-col>
        <v-col cols="12" lg="6">
          <h3 class="primary--text">Información del remitente</h3>
          <p class="mb-2">Tipo de persona</p>
          <v-chip
            color="info"
            label
            outlined
            small
            class="text-capitalize mb-4"
          >
            {{ documentSelected.type_person }}
          </v-chip>
          <p class="mb-2">
            {{
              documentSelected.type_person == "fisica"
                ? "Nombre"
                : "Razón social"
            }}
          </p>
          <p
            class="mb-4 font-weight-bold"
            v-text="documentSelected.social_reason"
          ></p>
          <p class="mb-2">
            {{ documentSelected.type_person == "fisica" ? "CURP" : "RFC" }}
          </p>
          <p class="mb-4 font-weight-bold" v-text="documentSelected.rfc"></p>
          <p class="mb-2">Correo electrónico</p>
          <p class="mb-4 font-weight-bold" v-text="documentSelected.email"></p>
          <p
            v-if="!!documentSelected.phone && documentSelected.phone != ''"
            class="mb-1"
          >
            Teléfono
          </p>
          <p
            v-if="!!documentSelected.phone && documentSelected.phone != ''"
            class="mb-4 font-weight-bold"
          >
            {{ documentSelected.phone | phone }}
          </p>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapState } from "vuex";
export default {
  //estructura base de vue
  name: "DocumentCard",
  mounted() {
    //validar si documentSelected existe
    if (Object.keys(this.documentSelected).length == 0) {
      this.$router.push("/documentos");
    }
  },
  methods: {
    seeFile() {
      window.open(this.server + this.documentSelected.file, "_blank");
    },
    getColor() {
      switch (this.documentSelected.status) {
        case "En proceso":
          return "primary";
        case "Atendido":
          return "success";
        case "Pendiente":
          return "warning";
        case "Denegado":
          return "error";
        default:
          return "info";
      }
    },
  },
  computed: {
    ...mapState({
      server: (state) => state.user.server,
      documentSelected: (state) => state.oficialia.documentSelected,
      isLoading: (state) => state.oficialia.isLoading,
    }),
  },
};
</script>

<style>
</style>