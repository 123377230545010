<template>
  <v-container fluid>
    <v-row align="center" justify="center">
      <v-col cols="12" md="10" lg="9">
        <v-btn rounded color="warning" large @click="$router.go(-1)">
          <v-icon left>mdi-arrow-left</v-icon>
          Regresar
        </v-btn>
      </v-col>
      <v-col cols="12" md="10" lg="9">
        <document-card></document-card>
      </v-col>
      <v-col cols="12" md="10" lg="9">
        <v-card :loading="isLoading" loader-height="10">
          <v-card-title class="info darken-3">
            <v-row>
              <v-col cols="12" md="8">
                <h5 class="white--text">Seguimiento del expediente</h5>
              </v-col>
              <v-col
                cols="12"
                md="4"
                class="d-flex align-end justify-center flex-column"
              >
                <v-menu offset-y>
                  <template v-slot:activator="{ on }">
                    <v-btn light large rounded v-on="on">
                      Opciones
                      <v-icon right>mdi-chevron-down</v-icon>
                    </v-btn>
                  </template>
                  <v-list light>
                    <v-list-item @click="showModal">
                      <v-icon left color="black">
                        mdi-cog-refresh-outline
                      </v-icon>
                      <v-list-item-title>
                        Actualizar estatus
                      </v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="showModalRecord">
                      <v-icon left color="black">mdi-progress-pencil</v-icon>
                      <v-list-item-title>
                        Registrar seguimiento
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </v-col>
            </v-row>
          </v-card-title>

          <v-card-text v-if="documentSelected.follows.length > 0">
            <v-data-table
              :headers="headers"
              :items="documentSelected.follows"
              :items-per-page="20"
              no-data-text="No hay actualizaciones registradas para este expediente"
            >
              <template v-slot:item.created_at="{ item }">
                <span>{{ item.created_at | date }}</span>
              </template>
              <template v-slot:item.user="{ item }">
                <span>{{ item.user.name + " " + item.user.lastname }}</span>
              </template>

              <template v-slot:item.actions="{ item }">
                <v-btn
                  v-if="!!item.file"
                  small
                  rounded
                  depressed
                  outlined
                  color="primary"
                  @click="seeFile(item.file)"
                >
                  <v-icon left>mdi-arrow-down</v-icon>
                  Ver evidencia
                </v-btn>
                <p class="mb-0 font-italic" v-else>Sin documento de respaldo</p>
              </template>
            </v-data-table>
          </v-card-text>
          <v-card-text v-else>
            <v-row>
              <v-col
                cols="12"
                class="d-flex justify-center align-center flex-column py-12"
              >
                <v-icon color="grey lighten-1" size="90" class="mb-6">
                  mdi-file-document-outline
                </v-icon>
                <h3 class="font-weight-regular grey--text text--lighten-1">
                  No hay actualizaciones registradas para este expediente
                </h3>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <update-status-modal :showModal="showUpdate" :cancel="hideModal" />
    <update-record-modal :showModal="showRecord" :cancel="hideModalRecord" />
  </v-container>
</template>
<script>
import { mapState } from "vuex";
import DocumentCard from "../../components/oficialia/DocumentCard.vue";
import UpdateRecordModal from "../../components/oficialia/UpdateRecordModal.vue";
import UpdateStatusModal from "./UpdateStatusModal.vue";
export default {
  components: { DocumentCard, UpdateStatusModal, UpdateRecordModal },
  mounted() {
    if (Object.keys(this.documentSelected).length == 0) {
      this.$router.replace("/documentos");
    }
  },
  data: () => ({
    hello: "",
    headers: [
      {
        text: "Fecha",
        align: "start",
        sortable: false,
        value: "created_at",
      },
      { text: "Descripción", value: "comment" },
      { text: "Usuario", value: "user" },
      { text: "Acciones", value: "actions", sortable: false },
    ],
    showUpdate: false,
    showRecord: false,
  }),
  methods: {
    seeFile(file) {
      window.open(this.server + file, "_blank");
    },
    showModal() {
      this.showUpdate = true;
    },
    hideModal() {
      this.showUpdate = false;
    },
    showModalRecord() {
      this.showRecord = true;
    },
    hideModalRecord() {
      this.showRecord = false;
    },
  },
  computed: {
    ...mapState({
      documentSelected: (state) => state.oficialia.documentSelected,
      server: (state) => state.user.server,
      isLoading: (state) => state.oficialia.isLoading,
    }),
  },
};
</script>