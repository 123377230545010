var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-col',{attrs:{"cols":"12","md":"10","lg":"9"}},[_c('v-btn',{attrs:{"rounded":"","color":"warning","large":""},on:{"click":function($event){return _vm.$router.go(-1)}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-arrow-left")]),_vm._v(" Regresar ")],1)],1),_c('v-col',{attrs:{"cols":"12","md":"10","lg":"9"}},[_c('document-card')],1),_c('v-col',{attrs:{"cols":"12","md":"10","lg":"9"}},[_c('v-card',{attrs:{"loading":_vm.isLoading,"loader-height":"10"}},[_c('v-card-title',{staticClass:"info darken-3"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"8"}},[_c('h5',{staticClass:"white--text"},[_vm._v("Seguimiento del expediente")])]),_c('v-col',{staticClass:"d-flex align-end justify-center flex-column",attrs:{"cols":"12","md":"4"}},[_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"light":"","large":"","rounded":""}},on),[_vm._v(" Opciones "),_c('v-icon',{attrs:{"right":""}},[_vm._v("mdi-chevron-down")])],1)]}}])},[_c('v-list',{attrs:{"light":""}},[_c('v-list-item',{on:{"click":_vm.showModal}},[_c('v-icon',{attrs:{"left":"","color":"black"}},[_vm._v(" mdi-cog-refresh-outline ")]),_c('v-list-item-title',[_vm._v(" Actualizar estatus ")])],1),_c('v-list-item',{on:{"click":_vm.showModalRecord}},[_c('v-icon',{attrs:{"left":"","color":"black"}},[_vm._v("mdi-progress-pencil")]),_c('v-list-item-title',[_vm._v(" Registrar seguimiento ")])],1)],1)],1)],1)],1)],1),(_vm.documentSelected.follows.length > 0)?_c('v-card-text',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.documentSelected.follows,"items-per-page":20,"no-data-text":"No hay actualizaciones registradas para este expediente"},scopedSlots:_vm._u([{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("date")(item.created_at)))])]}},{key:"item.user",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.user.name + " " + item.user.lastname))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(!!item.file)?_c('v-btn',{attrs:{"small":"","rounded":"","depressed":"","outlined":"","color":"primary"},on:{"click":function($event){return _vm.seeFile(item.file)}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-arrow-down")]),_vm._v(" Ver evidencia ")],1):_c('p',{staticClass:"mb-0 font-italic"},[_vm._v("Sin documento de respaldo")])]}}],null,false,835254776)})],1):_c('v-card-text',[_c('v-row',[_c('v-col',{staticClass:"d-flex justify-center align-center flex-column py-12",attrs:{"cols":"12"}},[_c('v-icon',{staticClass:"mb-6",attrs:{"color":"grey lighten-1","size":"90"}},[_vm._v(" mdi-file-document-outline ")]),_c('h3',{staticClass:"font-weight-regular grey--text text--lighten-1"},[_vm._v(" No hay actualizaciones registradas para este expediente ")])],1)],1)],1)],1)],1)],1),_c('update-status-modal',{attrs:{"showModal":_vm.showUpdate,"cancel":_vm.hideModal}}),_c('update-record-modal',{attrs:{"showModal":_vm.showRecord,"cancel":_vm.hideModalRecord}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }