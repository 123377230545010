
<template>
  <v-container class="gray lighten-2" fluid style="height: 100%">
    <v-row align="center" justify="center">
      <v-col cols="12" md="11" lg="9" xl="7">
        <v-card elevation="5">
          <v-card-title class="primary darken-2 px-3 px-md-7 py-6">
            <h5 class="white--text text-center">
              Completa el formulario para registrar tu documento
            </h5>
          </v-card-title>
          <v-card-text class="pa-0 pa-md-4">
            <v-row class="mx-0">
              <v-col cols="12" class="px-0 px-md-3">
                <v-stepper v-model="step" class="elevation-0">
                  <v-stepper-header class="elevation-0">
                    <v-stepper-step
                      step="1"
                      :complete="step > 1"
                      editable
                      @click="step = 1"
                    >
                      Documento
                    </v-stepper-step>
                    <v-divider></v-divider>
                    <v-stepper-step
                      step="2"
                      :complete="step > 2"
                      editable
                      @click="step = 2"
                    >
                      Remitente
                    </v-stepper-step>
                    <v-divider></v-divider>
                    <v-stepper-step step="3" :complete="step > 3">
                      Confirmación
                    </v-stepper-step>
                  </v-stepper-header>
                  <v-stepper-content step="1">
                    <v-card-text class="pa-0 pa-md-4">
                      <v-row class="mx-0">
                        <v-col cols="12" class="px-0 px-md-3">
                          <h3 class="">Información del documento</h3>
                        </v-col>
                      </v-row>
                      <v-row align="center" justify="center" class="mx-0">
                        <v-col cols="12" class="px-0 px-md-3">
                          <v-select
                            :items="document_types"
                            label="Tipo de documento"
                            outlined
                            v-model="document_type"
                          ></v-select>
                        </v-col>
                        <v-col cols="12" class="px-0 px-md-3">
                          <v-text-field
                            label="Asunto"
                            v-model="asunto"
                            :error="asunto_error != ''"
                            :error-messages="asunto_error"
                            outlined
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" class="px-0 px-md-3">
                          <v-text-field
                            label="Folios"
                            v-model="folio"
                            :error="folio_error != ''"
                            :error-messages="folio_error"
                            outlined
                          ></v-text-field>
                        </v-col>

                        <v-col cols="12" class="px-0 px-md-3">
                          <v-file-input
                            small-chips
                            v-model="file"
                            show-size
                            outlined
                            prepend-icon="mdi-attachment"
                            label="Archivos (Opcional)"
                            @change="selectFile"
                          ></v-file-input>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-stepper-content>
                  <v-stepper-content step="2">
                    <v-card-text class="pa-0 pa-md-4">
                      <v-row class="mx-0">
                        <v-col cols="12" class="px-0 px-md-3">
                          <h3 class="">Información del remitente</h3>
                        </v-col>
                      </v-row>
                      <v-row align="center" justify="center" class="mx-0">
                        <v-col cols="12" class="px-0 px-md-3">
                          <v-select
                            :items="person_types"
                            label="Tipo de remitente"
                            outlined
                            v-model="person_type"
                          ></v-select>
                        </v-col>
                        <v-col cols="12" class="px-0 px-md-3">
                          <v-text-field
                            :label="
                              person_type == 'fisica'
                                ? 'Nombre'
                                : 'Razón social'
                            "
                            outlined
                            :error="name_error != ''"
                            :error-messages="name_error"
                            v-model="name"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" class="px-0 px-md-3">
                          <v-text-field
                            :label="person_type == 'fisica' ? 'Curp' : 'RFC'"
                            v-model="rfc"
                            outlined
                            :error="rfc_error != ''"
                            :error-messages="rfc_error"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" class="px-0 px-md-3">
                          <v-text-field
                            label="Correo electrónico"
                            v-model="email"
                            outlined
                            :error="email_error != ''"
                            :error-messages="email_error"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" class="px-0 px-md-3">
                          <v-text-field
                            label="Teléfono (opcional)"
                            v-model="phone"
                            max-length="10"
                            outlined
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-stepper-content>
                  <v-stepper-content step="3">
                    <v-card-text class="pa-0 pa-md-4">
                      <resumen-card
                        :document_type="document_type"
                        :folios="folio"
                        :asunto="asunto"
                        :archivos="
                          file
                            ? '1 archivo seleccionado'
                            : 'Sin archivo seleccionado'
                        "
                        :person_type="person_type"
                        :rfc="rfc"
                        :email="email"
                        :name="name"
                        :phone="phone"
                      ></resumen-card>
                    </v-card-text>
                  </v-stepper-content>
                </v-stepper>
                <v-row align="center" justify="center" class="mx-0">
                  <v-col cols="12" md="4">
                    <v-btn
                      block
                      color="error"
                      rounded
                      large
                      :loading="isLoading"
                      :disabled="isLoading"
                      text
                      @click="showModal = true"
                    >
                      <v-icon left>mdi-close</v-icon>
                      Cancelar solicitud
                    </v-btn>
                  </v-col>
                  <v-col cols="12" md="4" v-if="step > 1">
                    <v-btn
                      block
                      color="grey"
                      rounded
                      large
                      :loading="isLoading"
                      :disabled="isLoading"
                      outlined
                      @click="goPreviousStep"
                    >
                      <v-icon left>mdi-arrow-left</v-icon>
                      Paso anterior
                    </v-btn>
                  </v-col>
                  <v-col cols="12" md="4">
                    <v-btn
                      block
                      color="primary"
                      rounded
                      large
                      :loading="isLoading"
                      :disabled="isLoading"
                      @click="goNextStep"
                    >
                      {{ step == 3 ? "Enviar documento" : "Siguiente paso" }}
                      <v-icon right>{{
                        step == 3 ? "mdi-content-save" : "mdi-arrow-right"
                      }}</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <confirm-modal :showModal="showModal" :confirm="exit" :cancel="cancel" />
    <confirm-modal
      :color="color"
      :showModal="showModalConfirm"
      :title="title"
      :text="text"
      :confirm="registerDocument"
      :cancel="cancelConfirm"
      :confirm_button_text="confirm_button_text"
      :cancel_button_text="cancel_button_text"
    />
    <success-modal
      :showModal="showModalSuccess"
      :onPressCancel="hideSuccess"
      :onPressConfirm="goToHome"
    />
  </v-container>
</template>
<script>
import ResumenCard from "../../components/oficialia/ResumenCard.vue";
import SuccessModal from "../../components/oficialia/SuccessModal.vue";
import ConfirmModal from "./ConfirmModal.vue";

export default {
  components: { ResumenCard, ConfirmModal, SuccessModal },
  name: "RegisterDocument",
  data() {
    return {
      // Variables
      document_type: "solicitud",
      document_types: [
        { text: "Solicitud", value: "solicitud" },
        { text: "Carta", value: "carta" },
        { text: "Oficio", value: "oficio" },
        { text: "Oficio multiple", value: "oficio multiple" },
        { text: "Otros", value: "otros" },
      ],
      person_types: [
        { text: "Física", value: "fisica" },
        { text: "Moral", value: "moral" },
      ],
      person_type: "fisica",
      folio: "",
      asunto: "",
      folio_error: "",
      asunto_error: "",
      file: [],
      step: 1,
      rfc: "",
      name: "",
      email: "",
      phone: "",
      showModal: false,
      showModalConfirm: false,
      color: "error",
      title: "¿Está seguro de cancelar la solicitud?",
      text: "La solicitud se cancelará inmediatamente. Esta acción no puede revertirse.",
      confirm_button_text: "",
      cancel_button_text: "",
      icon: "mdi-help-circle-outline",
      name_error: "",
      rfc_error: "",
      email_error: "",
      isLoading: false,
      currentFile: undefined,
      showModalSuccess: false,
    };
  },
  methods: {
    // Metodos
    registerDocument() {
      let formData = new FormData();
      formData.append("document_type", this.document_type);
      formData.append("person_type", this.person_type);
      formData.append("type", this.document_type);
      formData.append("folios", this.folio);
      formData.append("subject", this.asunto);
      if (this.currentFile) {
        formData.append("file", this.file);
      }
      formData.append("rfc", this.rfc);
      formData.append("social_reason", this.name);
      formData.append("type_person", this.person_type);
      formData.append("email", this.email);
      formData.append("phone", this.phone);
      //dispatch
      this.$store.dispatch("oficialia/registerDocument", formData).then(() => {
        this.isLoading = false;
        this.showModalConfirm = false;
        this.showModalSuccess = true;
      });
    },
    uploadFile() {
      console.log(this.file);
    },
    goNextStep() {
      if (this.step == 3) {
        this.isLoading = true;
        this.configureModalConfirm();
      } else if (this.step == 1) {
        if (this.validateFirstData()) {
          this.step++;
        }
      } else if (this.step == 2) {
        if (this.validateSecondData()) {
          this.step++;
        }
      }
    },
    goPreviousStep() {
      if (this.step > 1) {
        this.step--;
      }
    },
    validateFirstData() {
      let isValid = true;
      if (this.folio == "") {
        this.folio_error = "Indicanos el folio del documento";
        isValid = false;
      } else {
        this.folio_error = "";
      }
      if (this.asunto == "") {
        this.asunto_error = "Indicanos el asunto del documento";
        isValid = false;
      } else {
        this.asunto_error = "";
      }

      return isValid;
    },
    validateSecondData() {
      let isValid = true;
      if (this.rfc == "") {
        this.rfc_error = "Indicanos ";
        if (this.person_type == "fisica") {
          this.rfc_error += "la CURP";
        } else {
          this.rfc_error += "el RFC";
        }
        this.rfc_error += " del remitente";
        isValid = false;
      } else {
        this.rfc_error = "";
      }
      if (this.name == "") {
        this.name_error = "Indicanos el nombre del remitente";
        isValid = false;
      } else {
        this.name_error = "";
      }
      if (this.email == "") {
        this.email_error = "Indicanos el correo electrónico del remitente";
        isValid = false;
      } else {
        this.email_error = "";
      }
      return isValid;
    },

    configureModalConfirm() {
      this.color = "info";
      this.title = "¿Está seguro de enviar la solicitud?";
      this.text =
        "La solicitud se enviará inmediatamente para designarla al área correspondiente. Esta acción no puede revertirse.";
      this.confirm_button_text = "Si, enviar solicitud";
      this.cancel_button_text = "No, cancelar";
      this.icon = "mdi-help-circle-outline";
      this.showModalConfirm = true;
    },
    exit() {
      this.$router.go(-1);
    },
    cancel() {
      this.showModal = false;
    },
    cancelConfirm() {
      this.showModalConfirm = false;
      this.isLoading = false;
    },
    selectFile(file) {
      this.currentFile = file;
    },
  },
  computed: {
    // Computed
  },
};
</script>

