<template>
  <v-dialog
    v-model="showModal"
    :max-width="$vuetify.breakpoint.smAndDown ? '100vw' : '30vw'"
    persistent
  >
    <v-card>
      <v-card-text>
        <v-container>
          <v-row align="center" justify="center">
            <v-col cols="12">
              <div class="d-flex justify-center align-center flex-column">
                <v-icon left color="primary" size="80">
                  mdi-cog-refresh-outline
                </v-icon>
                <h3 class="primary--text text-center mt-5 mb-3">
                  Actualizar estatus del expediente
                </h3>
              </div>
            </v-col>
            <v-col cols="12">
              <v-select
                outlined
                v-model="new_status"
                :items="status_types"
                label="Nuevo estatus"
                :rules="rules"
                required
              ></v-select>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions class="justify-center">
        <v-row class="mx-0">
          <v-col cols="12" md="6" order="2" order-md="1">
            <v-btn color="primary" rounded block large text @click="cancel">
              Cancelar
            </v-btn>
          </v-col>
          <v-col cols="12" md="6" order="1" order-md="2">
            <v-btn color="primary" rounded block large @click="updateStatus">
              Actualizar estatus
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  props: {
    cancel: {
      type: Function,
      required: true,
    },
    showModal: {
      type: Boolean,
      required: true,
    },
  },
  data: () => ({
    new_status: "",
    status_types: ["Pendiente", "En trámite ", "Atendido", "Denegado"],
    rules: [(v) => !!v || "Este campo es requerido"],
  }),
  methods: {
    updateStatus() {
      this.$store
        .dispatch("oficialia/updateDocumentStatus", this.new_status)
        .then(() => {
          this.new_status = "";
          this.cancel();
        });
    },
  },
};
</script>
