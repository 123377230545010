<template>
  <v-dialog v-model="showModal" :max-width="getMaxWidth" persistent>
    <v-card :tile="$vuetify.breakpoint.smAndDown">
      <v-card-text>
        <div class="d-flex justify-center align-center flex-column">
          <v-icon :color="color" size="80">{{ icon }}</v-icon>
          <h3 class="primary--text text-center mt-5 mb-3">
            {{ title }}
          </h3>
        </div>
        <p class="text-center">
          {{ text }}
        </p>
      </v-card-text>

      <v-card-actions class="justify-center">
        <v-row class="mx-0">
          <v-col cols="12" md="6" order="2" order-md="1">
            <v-btn
              :loading="isLoading"
              :disabled="isLoading"
              color="primary"
              rounded
              block
              large
              text
              @click="cancel"
            >
              {{ cancel_button_text }}
            </v-btn>
          </v-col>
          <v-col cols="12" md="6" order="1" order-md="2">
            <v-btn
              :loading="isLoading"
              :disabled="isLoading"
              :color="color"
              rounded
              block
              large
              @click="confirm"
            >
              {{ confirm_button_text }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapState } from "vuex";
export default {
  props: {
    showModal: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "¿Está seguro de cancelar la solicitud?",
    },
    text: {
      type: String,
      default:
        "La solicitud se cancelará inmediatamente. Esta acción no puede revertirse.",
    },
    cancel: {
      type: Function,
      default: () => {},
    },
    confirm: {
      type: Function,
      default: () => {},
    },
    confirm_button_text: {
      type: String,
      default: "Si, cancelar documento",
    },
    cancel_button_text: {
      type: String,
      default: "No, regresar",
    },
    color: {
      type: String,
      default: "error",
    },
    icon: {
      type: String,
      default: "mdi-help-circle-outline",
    },
  },
  computed: {
    ...mapState({
      isLoading: (state) => state.oficialia.isLoading,
    }),
    getMaxWidth() {
      if (this.$vuetify.breakpoint.mdAndDown) {
        return "100vw";
      }
      if (this.$vuetify.breakpoint.lgOnly) {
        return "50vw";
      }
      return "40vw";
    },
  },
};
</script>