<template>
<v-container fluid class="gray pa-0 d-flex justify-center align-center login-container">
    <v-row align="center" justify="center">
        <v-col cols="8" md="5" lg="4">
            <v-card class="rounded-lg login-card cardP" flat  elevation="2">
                <v-card-title class="py-8">
                  <v-row>                  
                    <v-img class="imgS" :src="`${publicPath}imagenes/Header_img_pago.png`" height="120" contain></v-img>
                  </v-row>
                </v-card-title>
                <v-card-text>
                        <v-row  class="justify-center align-center">
                            <v-col cols="12" align="center" justify="center">
                                      <v-img
                                      :src="`${publicPath}imagenes/logos/Success.png`" 
                                      height="50" 
                                      contain ></v-img>                               
                                      <h3 class="text--center greenPayment--text mt-5" style="line-height: 24px;font-style: normal; font-weight: normal;">Tu pago se recibió</h3>
                                      <h3 class="text--center greenPayment--text " style="line-height: 24px;font-style: normal; font-weight: normal;">exitosamente.</h3>
                            </v-col>                            
                            <v-col cols="12" align="center" justify="center">
                                      <h4 class="text--center infoText" >Fecha de pago</h4>
                                      <h3 class="text--center dataText" >09 de Noviembre de 2021</h3>
                            </v-col>  
                            <v-col cols="12" align="center" justify="center">
                                      <h4 class="text--center infoText">Folio</h4>
                                      <h3 class="text--center dataText">0000000210</h3>
                            </v-col>  
                            <v-col cols="12" align="center" justify="center">
                                      <h4 class="text--center infotext">Trámite</h4>
                                      <h3 class="text--center dataText">Constancia de modo de vivir</h3>
                                      <h3 class="text--center dataText"> honesto.</h3>
                            </v-col>                                                                                    
                            <v-col cols="12">
                              <Button color="primary" :action="onPress" text="Ir a Mis trámites"/>
                            </v-col>                          
                        </v-row>
                </v-card-text>
            </v-card>
        </v-col>
    </v-row>
</v-container>
</template> 
<script>
  import Button from '../components/Button.vue';
  export default {
    components: { Button },
    data: () => ({
      publicPath: process.env.BASE_URL,
    }),
    methods:{
      onPress(){
        this.$router.push('/my-procedures-list');
      },
    }
  }
</script>
<style scoped>
  @import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
  .login-container {
    height: 100vh;
    width: 100%;
  }
  .cardP {
    padding-left: 30px;
    padding-right: 30px;
  }
  .imgS {
    margin-right: 30px;
  }
  .infoText{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 23px;
    color: #393E46;
  }
  .dataText{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 23px;
    color: #222831;
  }
</style>
