<template>
  <v-btn
    @click="action"
    x-large
    block
    :color="color"
    :outlined="whiteButton"
    :text="textButton"
    rounded
    :disabled="isDisabled"
  >
    <v-icon left size="70" :color="iconColor" v-if="requireIcon">
      {{ icon }}
    </v-icon>
    <span :class="{ textButton: 'primary--text' }">
      {{ text }}
    </span>
  </v-btn>
</template>
<style>
  @import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
  .buttonT {
    font-family: "Montserrat";
    font-size: 16px;
    line-height: 29px;
    align-items: center;
    text-align: center;
  }
</style>
<script>
  export default {
    props: {
      text: {
        type: String,
        required: true,
        default: "",
      },
      icon: {
        type: String,
        default: "mdi-calendar",
      },
      iconColor: {
        type: String,
        default: "white",
      },
      color: {
        type: String,
        required: true,
        default: "primary",
      },
      action: {
        type: Function,
        required: true,
      },
      requireIcon: {
        type: Boolean,
        default: false,
      },
      whiteButton: {
        type: Boolean,
        default: false,
      },
      textButton: {
        type: Boolean,
        default: false,
      },
      isLoading: {
        type: Boolean,
        default: false,
      },
    isDisabled:{
      type: Boolean,
      default: false,
    }      
    },
  };
</script>