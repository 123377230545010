<template>
  <v-row>
    <v-col cols="12" class="align-center justify-center pt-12">
      <v-card :tile="$vuetify.breakpoint.smAndDown" class="mx-9 pa-5">
        <v-card-title>
          <h4 class="primary--text" v-if="userFlag">Editar usuario</h4>
          <h4 class="primary--text" v-else>Crear un nuevo administrador</h4>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="name"
                label="Nombre(s)*"
                required
                outlined
                hide-details
                type="text"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                label="Apellidos*"
                outlined
                hide-details
                type="text"
                required
                v-model="lastnames"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                label="CURP*"
                outlined
                hide-details
                type="text"
                required
                maxlength="18"
                v-model="curp"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-menu
                ref="menu"
                v-model="menu"
                :close-on-content-click="false"
                :return-value.sync="date"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="date"
                    label="Fecha de nacimiento*"
                    prepend-inner-icon="mdi-calendar"
                    readonly
                    hide-details
                    outlined
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker v-model="date" no-title locale="es">
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="menu = false">
                    Cancelar
                  </v-btn>
                  <v-btn text color="primary" @click="$refs.menu.save(date)">
                    Aceptar
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                label="Celular*"
                v-model="phone"
                outlined
                hide-details
                maxlength="10"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-select
                :items="[
                  { value: 0, text: 'Hombre' },
                  { value: 1, text: 'Mujer' },
                  { value: 2, text: 'No binario' },
                ]"
                v-model="toggle_gender"
                label="Género"
                outlined
                hide-details
              ></v-select>
            </v-col>
            <v-col cols="12" md="6" v-if="userFlag">
              <v-select
                item-value="id"
                item-text="name"
                :items="dependecyList"
                label="Dependencia"
                v-model="userDependency"
                outlined
                hide-details
              ></v-select>
            </v-col>
            <v-col cols="12" md="6" v-if="!userFlag">
              <v-text-field
                label="Correo electrónico*"
                required
                :disabled="userFlag"
                outlined
                hide-details
                type="email"
                v-model="email"
                autocomplete="off"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6" v-if="!userFlag">
              <v-text-field
                label="Contraseña*"
                v-model="password"
                outlined
                hide-details
                required
                :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                :type="show1 ? 'text' : 'password'"
                autocomplete="off"
                @click:append="show1 = !show1"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6" v-if="!userFlag">
              <v-select
                item-value="id"
                item-text="name"
                :items="dependecyList"
                label="Dependencia"
                v-model="userDependency"
                outlined
                hide-details
              ></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <h3 class="primary--text">Domicilio del usuario</h3>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="6">
              <v-text-field
                label="Código postal *"
                v-model="zipcode"
                outlined
                hide-details
                required
                maxlength="5"
                type="tel"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                label="Colonia*"
                v-model="colony"
                outlined
                hide-details
                required
                type="text"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" md="6">
              <v-text-field
                label="Alcaldia/Municipio*"
                v-model="townHall"
                outlined
                hide-details
                required
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions class="justify-center">
          <v-row align="center" justify="center">
            <v-col cols="12" md="6" lg="4">
              <v-btn color="error" block large rounded text @click="onBack()">
                Cancelar registro</v-btn
              >
            </v-col>
            <v-col cols="12" md="6" lg="4">
              <v-btn
                color="primary"
                block
                large
                rounded
                @click="validateInputs()"
              >
                {{ userFlag ? "Actualizar usuario" : "Registrar usuario" }}
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
import { mapState } from "vuex";
import moment from "moment";

export default {
  mounted() {
    this.$store.dispatch("procedures/getDependencyList");
    console.log(this.userData);
    if (this.userFlag) {
      let gender = null;
      switch (this.userData.gender) {
        case "m":
          gender = 0;
          break;
        case "f":
          gender = 1;
          break;
        default:
          gender = 2;
          break;
      }
      this.toggle_gender = gender;
      this.name = this.userData.name;
      this.curp = this.userData.curp;
      this.lastnames = this.userData.lastname;
      this.date = this.userData.born_date;
      this.phone = this.userData.phone;
      this.mail = this.userData.mail;
      this.zipcode = this.userData.zip_code;
      this.colony = this.userData.colony;
      this.townHall = this.userData.municipality;
      this.userDependency = this.userData.dependency_id;
    }
  },
  data: () => ({
    toggle_gender: 0,
    name: "",
    lastnames: "",
    state: "",
    townHall: "",
    email: "",
    curp: "",
    mail: "",
    password: "",
    zipcode: "",
    colony: "",
    phone: "",
    userDependency: "",
    date: null,
    menu: false,
    show1: false,
  }),
  methods: {
    onBack() {
      this.$router.go(-1);
      this.clearInputs();
    },
    clearInputs() {
      this.toggle_gender = 0;
      this.name = "";
      this.lastnames = "";
      this.state = "";
      this.townHall = "";
      this.email = "";
      this.curp = "";
      this.mail = "";
      this.password = "";
      this.zipcode = "";
      this.colony = "";
      this.phone = "";
      this.userDependency = "";
      this.date = null;
    },
    validateInputs() {
      var now = moment(
        new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10)
      );
      var end = moment(this.date);
      var duration = moment.duration(now.diff(end));
      var years = duration.years();
      if (this.curp == "" || this.curp.length < 18 || this.curp.length > 18) {
        this.$store.dispatch(
          "alerts/setSnackbarText",
          "Ingrese una CURP válida"
        );
        this.$store.dispatch("alerts/setSnackbarColor", "warning");
        this.$store.dispatch("alerts/setSnackbar", true);
      } else if (this.name == "") {
        this.$store.dispatch(
          "alerts/setSnackbarText",
          "Nombre vacío, ingrese su nombre"
        );
        this.$store.dispatch("alerts/setSnackbarColor", "warning");
        this.$store.dispatch("alerts/setSnackbar", true);
      } else if (this.lastnames == "") {
        this.$store.dispatch(
          "alerts/setSnackbarText",
          "Apellidos vacíos, ingrese sus apellidos"
        );
        this.$store.dispatch("alerts/setSnackbarColor", "warning");
        this.$store.dispatch("alerts/setSnackbar", true);
      } else if (this.date == "" || this.date == null) {
        this.$store.dispatch(
          "alerts/setSnackbarText",
          "Seleccione la fecha de nacimiento"
        );
        this.$store.dispatch("alerts/setSnackbarColor", "warning");
        this.$store.dispatch("alerts/setSnackbar", true);
      } else if (years < 18) {
        this.$store.dispatch(
          "alerts/setSnackbarText",
          "Debe ser mayor de edad para crear una cuenta"
        );
        this.$store.dispatch("alerts/setSnackbarColor", "warning");
        this.$store.dispatch("alerts/setSnackbar", true);
      } else if (this.email == "" && !this.userFlag) {
        this.$store.dispatch(
          "alerts/setSnackbarText",
          "Correo electrónico vacío, ingrese su correo"
        );
        this.$store.dispatch("alerts/setSnackbarColor", "warning");
        this.$store.dispatch("alerts/setSnackbar", true);
      } else if (this.phone == "") {
        this.$store.dispatch(
          "alerts/setSnackbarText",
          "Número telefónico vacío, ingrese su número correcto"
        );
        this.$store.dispatch("alerts/setSnackbarColor", "warning");
        this.$store.dispatch("alerts/setSnackbar", true);
      } else if (this.password == "" && !this.userFlag) {
        this.$store.dispatch(
          "alerts/setSnackbarText",
          "Contraseña vacía, ingrese una contraseña"
        );
        this.$store.dispatch("alerts/setSnackbarColor", "warning");
        this.$store.dispatch("alerts/setSnackbar", true);
      } else if (this.password.length < 8 && !this.userFlag) {
        this.$store.dispatch(
          "alerts/setSnackbarText",
          "La contraseña debe contener mínimo 8 caracteres"
        );
        this.$store.dispatch("alerts/setSnackbarColor", "warning");
        this.$store.dispatch("alerts/setSnackbar", true);
      } else if (this.zipcode == "") {
        this.$store.dispatch(
          "alerts/setSnackbarText",
          "Codigo postal vacío, ingrese su cádigo postal"
        );
        this.$store.dispatch("alerts/setSnackbarColor", "warning");
        this.$store.dispatch("alerts/setSnackbar", true);
      } else if (this.colony == "") {
        this.$store.dispatch(
          "alerts/setSnackbarText",
          "Colonia vacía, ingrese su colonia"
        );
        this.$store.dispatch("alerts/setSnackbarColor", "warning");
        this.$store.dispatch("alerts/setSnackbar", true);
        // } else if (this.state == "") {
        //   this.$store.dispatch(
        //     "alerts/setSnackbarText",
        //     "Estado vacío, ingrese su estado"
        //   );
        //   this.$store.dispatch("alerts/setSnackbarColor", "warning");
        //   this.$store.dispatch("alerts/setSnackbar", true);
      } else if (this.townHall == "") {
        this.$store.dispatch(
          "alerts/setSnackbarText",
          "Municipio vacío, ingrese su municipio"
        );
        this.$store.dispatch("alerts/setSnackbarColor", "warning");
        this.$store.dispatch("alerts/setSnackbar", true);
      } else {
        if (this.userFlag) {
          this.editUser();
        } else {
          this.registerUser();
        }
      }
    },
    editUser() {
      var self = this;
      let gender = null;
      switch (this.toggle_gender) {
        case 0:
          gender = "m";
          break;
        case 1:
          gender = "f";
          break;
        default:
          gender = "s";
          break;
      }
      let params = {
        name: self.name,
        lastname: self.lastnames,
        born_date: self.date,
        gender: gender,
        curp: self.curp,
        email: self.email,
        password: self.password,
        zip_code: self.zipcode,
        colony: self.colony,
        municipality: self.townHall,
        phone: self.phone,
        dependency_id: self.userDependency,
        id: this.userData.id,
        role: "Administrador",
      };

      this.$store
        .dispatch("user/editUser", params)
        .then(() => {
          this.$store.dispatch(
            "alerts/setSnackbarText",
            "Usuario editado correctamente"
          );
          this.$store.dispatch("alerts/setSnackbarColor", "success");
          this.$store.dispatch("alerts/setSnackbar", true);
          this.$router.go(-1);
        })
        .catch(() => {
          this.$store.dispatch(
            "alerts/setSnackbarText",
            "Algo salió mal, revise que el correo o CURP no se encuentre en uso."
          );
          this.$store.dispatch("alerts/setSnackbarColor", "error");
          this.$store.dispatch("alerts/setSnackbar", true);
        });
    },
    registerUser() {
      let params = {
        name: this.name,
        lastname: this.lastnames,
        born_date: this.date,
        curp: this.curp,
        gender:
          self.toggle_gender == 0 ? "m" : self.toggle_gender == 1 ? "f" : "s",
        email: this.email,
        password: this.password,
        zip_code: this.zipcode,
        colony: this.colony,
        municipality: this.townHall,
        phone: this.phone,
        dependency_id: this.userDependency,
        role: "Administrador",
      };

      this.$store
        .dispatch("user/registerNewUser", params)
        .then(() => {
          this.$store.dispatch(
            "alerts/setSnackbarText",
            "Usuario creado correctamente"
          );
          this.$store.dispatch("alerts/setSnackbarColor", "success");
          this.$store.dispatch("alerts/setSnackbar", true);
          this.$router.go(-1);
        })
        .catch(() => {
          this.$store.dispatch(
            "alerts/setSnackbarText",
            "Algo salió mal, revise que el correo o CURP no se encuentre en uso."
          );
          this.$store.dispatch("alerts/setSnackbarColor", "error");
          this.$store.dispatch("alerts/setSnackbar", true);
        });
    },
    onPressConfirm() {
      //Agregar conndicion para valida si va a editar o agregar uno nuevo
    },
  },
  computed: {
    ...mapState({
      userData: (state) => state.user.userDataEdit,
      userFlag: (state) => state.user.userDataFlag,
      dependecyList: (state) => state.procedures.dependecyList,
    }),
  },
};
</script>
