<template>
  <v-container fluid>
    <v-row align="center" justify="center">
      <v-col cols="12" md="6" lg="5">
        <v-card elevation="2">
          <v-card-title class="py-4 primary">
            <v-btn depressed text @click="onClickImage" color="white" large>
              <v-icon left>mdi-arrow-left</v-icon>
              Regresar
            </v-btn>
          </v-card-title>
          <v-card-text>
            <v-row v-if="step == 1">
              <v-col
                cols="12"
                align="center"
                justify="center"
                class="backgroundGray"
              >
                <h4 class="text--center createAccount my-3">Crea tu cuenta</h4>
                <h3 class="text--center seconText">
                  Completa la siguiente información para crear tu cuenta en el
                  <strong>Ayuntamiento de Tepic </strong>
                </h3>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  rounded
                  label="CURP*"
                  outlined
                  hide-details
                  type="text"
                  required
                  maxlength="18"
                  v-model="curp"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  rounded
                  label="Nombre(s)*"
                  outlined
                  hide-details
                  type="text"
                  required
                  v-model="name"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  rounded
                  label="Apellidos*"
                  outlined
                  hide-details
                  type="text"
                  required
                  v-model="lastnames"
                ></v-text-field>
              </v-col>
              <v-col cols="12" class="mb-0 pb-0">
                <v-menu
                  ref="menu"
                  v-model="menu"
                  :close-on-content-click="false"
                  :return-value.sync="date"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="date"
                      label="Fecha de nacimiento*"
                      prepend-inner-icon="mdi-calendar"
                      readonly
                      hide-details
                      rounded
                      outlined
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker v-model="date" no-title scrollable locale="es">
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="menu = false">
                      Cancelar
                    </v-btn>
                    <v-btn text color="primary" @click="$refs.menu.save(date)">
                      Aceptar
                    </v-btn>
                  </v-date-picker>
                </v-menu>
                <v-col cols="12" class="mt-0">
                  <p class="mb-0 text--center text-center">Sexo*</p>
                  <v-col cols="12" class="d-flex justify-center">
                    <v-btn-toggle
                      v-model="toggle_gender"
                      mandatory
                      color="primary"
                    >
                      <v-btn @click="toggle_gender = 0">
                        <v-icon left>mdi-gender-male</v-icon>
                        Hombre
                      </v-btn>
                      <v-btn @click="toggle_gender = 1">
                        <v-icon left>mdi-gender-female</v-icon>
                        Mujer
                      </v-btn>
                      <v-btn @click="toggle_gender = 2">
                        <v-icon left>mdi-checkbox-blank-circle-outline</v-icon>
                        Sin especificar
                      </v-btn>
                    </v-btn-toggle>
                  </v-col>
                </v-col>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  rounded
                  label="Celular*"
                  v-model="phone"
                  outlined
                  hide-details
                  maxlength="10"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  rounded
                  label="Correo electrónico*"
                  outlined
                  hide-details
                  v-model="email"
                  required
                  autocomplete="new-text"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  label="Contraseña*"
                  v-model="password"
                  rounded
                  outlined
                  hide-details
                  :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="show1 ? 'text' : 'password'"
                  required
                  autocomplete="new-password"
                  @click:append="show1 = !show1"
                />
              </v-col>
              <v-col cols="12">
                <Button
                  color="primary"
                  :action="onPressNext"
                  text="Continuar"
                />
              </v-col>
            </v-row>
            <v-row v-else>
              <v-col
                cols="12"
                align="center"
                justify="center"
                class="backgroundGray"
              >
                <h4 class="text--center createAccount my-3">Crea tu cuenta</h4>
                <h3 class="text--center seconText">
                  Completa la siguiente información para crear tu cuenta en el
                  <strong>Ayuntamiento de Tepic </strong>
                </h3>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  rounded
                  label="Código postal*"
                  v-model="zipcode"
                  outlined
                  hide-details
                  required
                  maxlength="5"
                  type="tel"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  label="Colonia*"
                  v-model="colony"
                  rounded
                  outlined
                  hide-details
                  required
                />
              </v-col>
              <!-- <v-col cols="12">
                <v-text-field
                  rounded
                  label="Estado*"
                  v-model="state"
                  outlined
                  hide-details
                  required
                ></v-text-field>
              </v-col> -->
              <v-col cols="12">
                <v-text-field
                  label="Alcaldia/Municipio*"
                  v-model="townHall"
                  outlined
                  rounded
                  hide-details
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <Button
                  color="primary"
                  :action="onPressNext"
                  text="Finalizar"
                />
              </v-col>
              <v-col cols="12">
                <Button
                  color="primary"
                  whiteButton
                  :action="onClickImage"
                  text="Regresar"
                />
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import Button from "../components/Button.vue";
import moment from "moment";
export default {
  components: { Button },
  data: () => ({
    step: 1,
    toggle_gender: 0,
    name: "",
    lastnames: "",
    state: "",
    townHall: "",
    email: "",
    curp: "",
    mail: "",
    password: "",
    zipcode: "",
    colony: "",
    phone: "",
    show1: false,
    date: null,
    menu: false,
  }),
  methods: {
    onPressNext() {
      if (this.step == 1) {
        this.step++;
      } else {
        this.validateInputs();
      }
    },
    onClickImage() {
      if (this.step == 1) {
        this.$router.go(-1);
      } else {
        this.step = 1;
      }
    },
    validateInputs() {
      var now = moment(
        new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10)
      );
      var end = moment(this.date);
      var duration = moment.duration(now.diff(end));
      var years = duration.years();
      if (this.curp == "" || this.curp.length < 18 || this.curp.length > 18) {
        this.$store.dispatch(
          "alerts/setSnackbarText",
          "Ingrese una CURP válida"
        );
        this.$store.dispatch("alerts/setSnackbarColor", "warning");
        this.$store.dispatch("alerts/setSnackbar", true);
      } else if (this.name == "") {
        this.$store.dispatch(
          "alerts/setSnackbarText",
          "Nombre vacío, ingrese su nombre"
        );
        this.$store.dispatch("alerts/setSnackbarColor", "warning");
        this.$store.dispatch("alerts/setSnackbar", true);
      } else if (this.lastnames == "") {
        this.$store.dispatch(
          "alerts/setSnackbarText",
          "Apellidos vacíos, ingrese sus apellidos"
        );
        this.$store.dispatch("alerts/setSnackbarColor", "warning");
        this.$store.dispatch("alerts/setSnackbar", true);
      } else if (this.date == "" || this.date == null) {
        this.$store.dispatch(
          "alerts/setSnackbarText",
          "Seleccione la fecha de nacimiento"
        );
        this.$store.dispatch("alerts/setSnackbarColor", "warning");
        this.$store.dispatch("alerts/setSnackbar", true);
      } else if (years < 18) {
        this.$store.dispatch(
          "alerts/setSnackbarText",
          "Debe ser mayor de edad para crear una cuenta"
        );
        this.$store.dispatch("alerts/setSnackbarColor", "warning");
        this.$store.dispatch("alerts/setSnackbar", true);
      } else if (this.email == "") {
        this.$store.dispatch(
          "alerts/setSnackbarText",
          "Correo electrónico vacío, ingrese su correo"
        );
        this.$store.dispatch("alerts/setSnackbarColor", "warning");
        this.$store.dispatch("alerts/setSnackbar", true);
      } else if (this.phone == "") {
        this.$store.dispatch(
          "alerts/setSnackbarText",
          "Número telefónico vacío, ingrese su número correcto"
        );
        this.$store.dispatch("alerts/setSnackbarColor", "warning");
        this.$store.dispatch("alerts/setSnackbar", true);
      } else if (this.password == "") {
        this.$store.dispatch(
          "alerts/setSnackbarText",
          "Contraseña vacía, ingrese una contraseña"
        );
        this.$store.dispatch("alerts/setSnackbarColor", "warning");
        this.$store.dispatch("alerts/setSnackbar", true);
      } else if (this.password.length < 8) {
        this.$store.dispatch(
          "alerts/setSnackbarText",
          "La contraseña debe contener mínimo 8 caracteres"
        );
        this.$store.dispatch("alerts/setSnackbarColor", "warning");
        this.$store.dispatch("alerts/setSnackbar", true);
      } else if (this.zipcode == "") {
        this.$store.dispatch(
          "alerts/setSnackbarText",
          "Codigo postal vacío, ingrese su cádigo postal"
        );
        this.$store.dispatch("alerts/setSnackbarColor", "warning");
        this.$store.dispatch("alerts/setSnackbar", true);
      } else if (this.colony == "") {
        this.$store.dispatch(
          "alerts/setSnackbarText",
          "Colonia vacía, ingrese su colonia"
        );
        this.$store.dispatch("alerts/setSnackbarColor", "warning");
        this.$store.dispatch("alerts/setSnackbar", true);
        // } else if (this.state == "") {
        //   this.$store.dispatch(
        //     "alerts/setSnackbarText",
        //     "Estado vacío, ingrese su estado"
        //   );
        //   this.$store.dispatch("alerts/setSnackbarColor", "warning");
        //   this.$store.dispatch("alerts/setSnackbar", true);
        // }
      } else if (this.townHall == "") {
        this.$store.dispatch(
          "alerts/setSnackbarText",
          "Municipio vacío, ingrese su municipio"
        );
        this.$store.dispatch("alerts/setSnackbarColor", "warning");
        this.$store.dispatch("alerts/setSnackbar", true);
      } else {
        this.registerUser();
      }
    },
    registerUser() {
      let gender = null;
      switch (this.toggle_gender) {
        case 0:
          gender = "m";
          break;
        case 1:
          gender = "f";
          break;
        default:
          gender = "s";
          break;
      }
      let params = {
        name: this.name,
        lastname: this.lastnames,
        born_date: this.date,
        curp: this.curp,
        gender: gender,
        email: this.email,
        password: this.password,
        zip_code: this.zipcode,
        colony: this.colony,
        municipality: this.townHall,
        phone: this.phone,
        role: "Usuario",
      };
      this.$store
        .dispatch("user/registerNewUser", params)
        .then(() => {
          this.$store.dispatch(
            "alerts/setSnackbarText",
            "Usuario creado correctamente, inicie sesión para ingresar."
          );
          this.$store.dispatch("alerts/setSnackbarColor", "success");
          this.$store.dispatch("alerts/setSnackbar", true);
          this.$router.push("/");
        })
        .catch(() => {
          this.$store.dispatch(
            "alerts/setSnackbarText",
            "Algo salió mal, revise que el correo o CURP no se encuentre en uso."
          );
          this.$store.dispatch("alerts/setSnackbarColor", "error");
          this.$store.dispatch("alerts/setSnackbar", true);
        });
    },
    onPressCurp() {
      let params = {
        curp: this.curp,
      };
      this.$store.dispatch("user/consultCURP", params);
    },
  },
};
</script>
<style scoped>
.login-container {
  height: 100%;
  width: 100%;
}
.backgroundGray {
  background: rgba(247, 247, 248, 255);
}
.createAccount {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 23px;
  color: #9b2040;
}
.seconText {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 23px;
  color: #222831;
}
.inputC {
  box-sizing: border-box;
  border-radius: 12px;
}
.infoText {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 15px;
  letter-spacing: 0.07px;
  color: #393e46;
}
.dataText {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 15px;
  letter-spacing: 0.07px;
  color: #222831;
}
</style>
