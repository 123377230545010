<template>
  <v-container fluid class="pt-8">
    <v-alert
      v-if="personalDetailProcedure.expires_at"
      dense
      text
      type="success"
      align="center"
    >
      Tu documentación ha sido validada exitosamente. Ya puedes pasar a la
      oficina en el horario disponible en la fecha:
      <strong>{{ personalDetailProcedure.expires_at }}</strong>
    </v-alert>
    <v-row>
      <v-col cols="12" md="6" lg="3">
        <v-card
          class="rounded-lg"
          outlined
          elevation="2"
          style="border-radius: 6px"
        >
          <p
            class="
              justify-center
              align-center
              text-center
              mb-0
              font-weight-bold
              grey
              lighten-3
              py-2
              text-uppercase
            "
          >
            {{ personalDetailProcedure.procedure.dependecy.name }}
          </p>
          <v-card-text>
            <v-col align="center" justify="center" cols="12">
              <h4 class="primary--text">
                {{ personalDetailProcedure.procedure.name }}
              </h4>
              <br />
              <p class="mb-0">Población objetivo</p>
              <h4 class="black--text">
                {{ personalDetailProcedure.procedure.objetive }}
              </h4>
            </v-col>
            <v-col cols="12" align="center" justify="center">
              <p class="mb-0">Te sirve para</p>
              <h4 class="black--text">
                {{ personalDetailProcedure.procedure.serves_for }}
              </h4>
            </v-col>
            <v-col
              cols="12"
              align="center"
              justify="center"
              v-if="!!personalDetailProcedure.pdf"
            >
              <v-btn
                :href="server + personalDetailProcedure.pdf"
                target="_blank"
                x-large
                block
                color="primary"
                rounded
              >
                <small> Descargar acuse de solicitud</small>
              </v-btn>
            </v-col>
          </v-card-text>
        </v-card>
        <v-card class="rounded-lg mt-6" flat elevation="2">
          <p
            class="
              justify-center
              align-center
              text-center
              mb-0
              font-weight-bold
              grey
              lighten-3
              py-2
              text-uppercase
            "
          >
            Dirección
          </p>
          <v-card-text>
            <v-col cols="12" align="center" justify="center">
              <v-avatar color="primary" class="mb-4">
                <v-icon dark> mdi-map-marker </v-icon>
              </v-avatar>
              <h3 class="text--center black--text">
                {{ personalDetailProcedure.procedure.dependecy.address }}
              </h3>
            </v-col>
            <v-col cols="12" align="center" justify="center ">
              <p class="text--center mb-0">Horario</p>
              <h3 class="text--center black--text">
                {{ personalDetailProcedure.procedure.dependecy.schedule }}
              </h3>
            </v-col>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="6" :lg="isAdminReview ? 6 : 9">
        <v-card
          class="rounded-lg"
          outlined
          elevation="2"
          style="border-radius: 6px"
        >
          <p
            class="
              justify-center
              align-center
              text-center
              mb-0
              font-weight-bold
              grey
              lighten-3
              py-2
              text-uppercase
            "
          >
            {{
              userLogued.role == "Administrador"
                ? "Documentación subida"
                : "Documentación necesaria"
            }}
          </p>
          <v-card-text>
            <v-row v-if="userLogued.role == 'Administrador'">
              <v-col
                cols="12"
                v-for="(item, index) in personalDetailProcedure.procedure
                  .requirements"
                :key="index"
                :class="
                  renderDocumentUploaded(item.id) == 'Rechazado'
                    ? 'primary white--text'
                    : ''
                "
              >
                <requirement-card
                  :requirementTitle="item.name"
                  :requirementType="item.type == null ? '' : item.type"
                  :clickCard="() => openDialogAdmin(item)"
                  :completeRequirement="isDocumentUploaded(item.id)"
                  :status="isInRevision(item.id)"
                ></requirement-card>
              </v-col>
              <v-col cols="12" align="center" justify="center"> </v-col>
              <v-col cols="12" align="center" justify="center">
                <div>
                  <v-btn
                    x-large
                    :disabled="is_disabled()"
                    block
                    color="primary"
                    rounded
                    @click="openDialogExpire()"
                  >
                    <small> Validar los documentos</small>
                  </v-btn>
                </div>
              </v-col>
            </v-row>
            <v-row v-else>
              <v-col
                cols="12"
                v-for="(item, index) in personalDetailProcedure.procedure
                  .requirements"
                :key="index"
              >
                <requirement-card
                  :requirementTitle="item.name"
                  :requirementType="item.type == null ? '' : item.type"
                  :clickCard="() => openDialog(item)"
                  :completeRequirement="isDocumentUploaded(item.id)"
                  :status="isInRevision(item.id)"
                ></requirement-card>
              </v-col>
            </v-row>
          </v-card-text>
          <v-col cols="12" align="center" justify="center">
            <Button
              color="primary"
              :action="downloadFile"
              text="Descargar Padrón"
            />
          </v-col>
        </v-card>
      </v-col>
      <v-col cols="12" md="6" lg="3" v-if="isAdminReview">
        <v-card class="rounded-lg" flat elevation="2">
          <p
            class="
              justify-center
              align-center
              text-center
              mb-0
              font-weight-bold
              grey
              lighten-3
              py-2
              text-uppercase
            "
          >
            Contribuyente
          </p>
          <v-card-text v-if="!!personalDetailProcedure.user">
            <v-col cols="12" align="center" justify="center">
              <v-avatar color="primary" class="mb-4">
                <v-icon dark> mdi-account </v-icon>
              </v-avatar>
              <h3 class="text--center black--text">
                {{
                  personalDetailProcedure.user.name +
                  " " +
                  personalDetailProcedure.user.lastname
                }}
              </h3>
            </v-col>
            <v-col cols="12" align="center" justify="center ">
              <p class="text--center mb-0">CURP</p>
              <h3 class="text--center black--text">
                {{ personalDetailProcedure.user.curp }}
              </h3>
              <p class="text--center mb-0 mt-2">Teléfono</p>
              <h3 class="text--center black--text">
                {{ personalDetailProcedure.user.phone }}
              </h3>
              <p class="text--center mb-0 mt-2">Correo electrónico</p>
              <h3 class="text--center black--text">
                {{ personalDetailProcedure.user.email }}
              </h3>
              <p class="text--center mb-0 mt-2">Domicilio</p>
              <h3 class="text--center black--text">
                {{
                  personalDetailProcedure.user.zip_code +
                  " / " +
                  personalDetailProcedure.user.colony
                    .substr(0, 1)
                    .toUpperCase() +
                  personalDetailProcedure.user.colony.substr(
                    1,
                    personalDetailProcedure.user.colony.length
                  )
                }}
              </h3>
              <h3 class="text--center black--text">
                {{ personalDetailProcedure.user.municipality }}
              </h3>
            </v-col>
          </v-card-text>
          <v-card-text v-else>
            <v-col cols="12" align="center" justify="center">
              <v-avatar color="primary" class="mb-4">
                <v-icon dark> mdi-account </v-icon>
              </v-avatar>
              <h3 class="text--center black--text">
                No hay información de este contribuyente
              </h3>
            </v-col>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog v-model="dialog" width="500" persistent v-if="dialog">
      <v-card>
        <v-card-title class="py-4 primary">
          <v-btn depressed text @click="closeDialog" color="white" large>
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>
          <h5 class="white--text ml-1">
            {{
              isDocumentUploaded(requirementData.id)
                ? "Requisito subido"
                : "Sube tu comprobante"
            }}
          </h5>
        </v-card-title>

        <v-card-text class="px-5 py-5">
          <v-row>
            <v-col cols="12">
              <v-card outlined>
                <v-card-text>
                  <h3 class="primary--text text--darken-3">
                    {{ requirementData.name }}
                  </h3>
                  <p class="mb-0">
                    {{ requirementData.type }}
                  </p>
                  <v-chip
                    label
                    outlined
                    small
                    class="my-2"
                    :color="
                      isDocumentUploaded(requirementData.id)
                        ? 'success'
                        : 'grey'
                    "
                    >{{
                      isDocumentUploaded(requirementData.id)
                        ? "Documento subido"
                        : "Documento pendiente"
                    }}</v-chip
                  >
                  <v-col
                    cols="12"
                    v-if="isDocumentUploaded(requirementData.id)"
                  >
                    <Button
                      color="primary"
                      :action="openModalImg"
                      text="Ver imagen"
                    />
                  </v-col>
                  <v-chip
                    label
                    outlined
                    small
                    class="my-2 mx-2"
                    v-if="isDocumentUploaded(requirementData.id)"
                    :color="renderStatus(uploadedData.status, 'color')"
                    >{{ renderStatus(uploadedData.status, "text") }}</v-chip
                  >
                  <p v-if="uploadedData.status == 'Rechazada'">
                    Observaciones: {{ uploadedData.observation }}
                  </p>

                  <v-img
                    class="mt-3"
                    height="250"
                    contain
                    v-if="isDocumentUploaded(requirementData.id)"
                    :src="ruta"
                  ></v-img>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col
              cols="12"
              align="center"
              justify="center"
              v-if="userLogued.role == 'Administrador' && can_edit"
            >
              <v-btn-toggle v-model="statusUploaded" mandatory class="mb-4">
                <v-btn> <v-icon>mdi-check</v-icon> Aceptar </v-btn>
                <v-btn> <v-icon>mdi-close</v-icon> Rechazar </v-btn>
              </v-btn-toggle>
              <v-textarea
                v-if="statusUploaded == 1"
                solo
                v-model="observations"
                name="input-7-4"
                label="Escriba el motivo de rechazo"
              ></v-textarea>
              <v-btn
                @click="saveStatusUploadedData()"
                x-large
                block
                color="primary"
                rounded
              >
                <small> Actualizar estatus de documento</small>
              </v-btn>
            </v-col>
          </v-row>
          <file-select
            class="mt-5"
            v-model="file"
            v-if="
              !isAdminReview &&
              is_disabled() &&
              can_edit &&
              uploadedData.status != 'Aprobada'
            "
          ></file-select>
        </v-card-text>
        <v-row
          v-if="
            !isAdminReview &&
            is_disabled() &&
            can_edit &&
            uploadedData.status != 'Aprobada'
          "
        >
          <v-divider></v-divider>
          <v-col cols="12" v-if="!isDocumentUploaded(requirementData.id)">
            <Button
              color="primary"
              :action="uploadFile"
              text="Subir Requisito"
              :isDisabled="file ? false : true"
            />
          </v-col>
          <v-col cols="12" v-else>
            <Button
              color="primary"
              :action="updateFile"
              text="Actualizar Requisito"
              :isDisabled="file ? false : true"
            />
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
    <v-dialog v-model="imgDialog" fullscreen persistent>
      <v-card>
        <v-card-title class="py-4 primary">
          <v-btn depressed text @click="imgDialog = false" color="white" large>
            <v-icon>mdi-close-circle</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-img
            class="mt-3"
            contain
            v-if="isDocumentUploaded(requirementData.id)"
            :src="ruta"
          ></v-img>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="dialog2"
      :return-value.sync="date"
      persistent
      width="290px"
    >
      <v-date-picker
        v-model="date"
        :min="$moment().format('YYYY-MM-DD')"
        scrollable
        locale="es"
      >
        <v-spacer></v-spacer>
        <v-btn text color="primary" @click="dialog2 = false"> Cancelar </v-btn>
        <v-btn text color="primary" @click="setExpireFile()"> Aceptar </v-btn>
      </v-date-picker>
    </v-dialog>
    <v-btn
      fab
      color="primary"
      style="
        z-index: 6;
        cursor: pointer;
        position: fixed;
        bottom: 20px;
        right: 20px;
        height: 60px;
        width: 60px;
      "
      @click="actualizar()"
    >
      <v-icon dark>mdi mdi-update</v-icon>
    </v-btn>
  </v-container>
</template>

<script>
import RequirementCard from "../components/RequirementCard.vue";
import FileSelect from "../components/FileSelector.vue";
import Button from "../components/Button.vue";
import { mapState } from "vuex";

export default {
  mounted() {
    
    // eslint-disable-next-line no-prototype-builtins
    //this.verify();
  },
  components: { RequirementCard, FileSelect, Button },

  data: () => ({
    publicPath: process.env.BASE_URL,
    dependencyData: {},
    imgDialog: false,
    dialog: false,
    file: null,
    ruta: null,
    isValid: false,
    statusUploaded: 0,
    observations: null,
    uploadedData: {
      id: null,
      observation: null,
      status: "Pendiente",
    },
    requirementData: {
      id: 1,
      name: "CURP",
      status: "Pendiente",
    },
    can_edit: true,
    date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    dialog2: false,
  }),

  methods: {
    is_disabled() {
      let bool = true;
      if (this.personalDetailProcedure.expires_at != null) {
        this.can_edit = false;
        return true;
      }
      if (
        !!this.personalDetailProcedure.uploadrequeriments &&
        this.personalDetailProcedure.procedure.requirements.length ==
          this.personalDetailProcedure.uploadrequeriments.length
      ) {
        bool = false;
      }
      this.personalDetailProcedure.uploadrequeriments.forEach((element) => {
        if (element.status != "Aprobada") {
          bool = true;
        }
      });

      return bool;
    },
    renderStatus(status, type) {
      if (type == "text") {
        switch (status) {
          case "Pendiente":
            return "Pendiente de validación";
          case "Aprobada":
            return "Aprobado";
          default:
            return "Rechazado";
        }
      } else {
        switch (status) {
          case "Pendiente":
            return "warning";
          case "Aprobada":
            return "green";
          default:
            return "red";
        }
      }
    },
    downloadFile() {
      let params = {
        id: this.personalDetailProcedure.id,
      };
      this.$store
        .dispatch("procedures/dowloadProcedurePattern", params)
        .then((data) => {
          var wnd = window.open("about:blank", "", "_blank");
          wnd.document.write("" + data.data);
          window.open(data);
        })
        .catch(() => {
          this.$store.dispatch(
            "alerts/setSnackbarText",
            "Algo salio mal , intentelo más tarde"
          );
          this.$store.dispatch("alerts/setSnackbarColor", "error");
          this.$store.dispatch("alerts/setSnackbar", true);
          this.loadingLogin = false;
        });
      //Funcion donde se descargará el padron del tramite solicitado
    },
    saveStatusUploadedData() {
      if (this.statusUploaded == 1 && this.observations == null) {
        this.$store.dispatch(
          "alerts/setSnackbarText",
          "Indique el motivo de rechazo del documento."
        );
        this.$store.dispatch("alerts/setSnackbarColor", "error");
        this.$store.dispatch("alerts/setSnackbar", true);
        return;
      }
      var self = this;
      let params = {
        id: self.uploadedData.id,
        observations: self.observations,
        status: self.statusUploaded == 0 ? "Aprobada" : "Rechazada",
        list_procedure_id: self.uploadedData.list_procedure_id,
      };
      this.$store.dispatch("user/setStatusUploadedData", params).then(() => {
        this.$store.dispatch(
          "alerts/setSnackbarText",
          "Estatus de documento actualizado."
        );
        this.updateRequirement();
        this.$store.dispatch("alerts/setSnackbarColor", "success");
        this.$store.dispatch("alerts/setSnackbar", true);
        this.uploadedData = {
          id: null,
          observation: null,
          status: "Pendiente",
        };
        this.dialog2 = false;
        this.dialog = false;
      });
    },
    openDialog(item) {
      var self = this;
      self.obtenerRuta(item.id, 1);
      self.requirementData = item;
      self.dialog = true;
      //console.log(this.uploadedData.status);
    },
    openDialogAdmin(item) {
      var self = this;
      self.observations = "";
      self.obtenerRuta(item.id, 1);
      if (item.id == self.uploadedData.requirements_id) {
        self.isValid = true;
      } else {
        self.isValid = false;
      }
      if (self.isValid) {
        self.requirementData = item;
        self.dialog = true;
      }
    },
    openDialogExpire() {
      var self = this;
      if (this.userLogued.role == "Administrador") {
        self.dialog2 = true;
      } else {
        self.dialog = true;
      }
    },
    openModalImg() {
      this.imgDialog = true;
      console.log(this.requirementData);
      console.log(this.uploadedData);
    },
    closeDialog() {
      this.file = null;
      this.dialog = false;
      if (this.userLogued.role == "Administrador") {
        this.dialo2 = false;
      } else {
        this.requirementData = {
          id: 1,
          name: "CURP",
          status: "Pendiente",
        };
      }
    },
    uploadFile() {
      // console.log(this.requirementData);
      let params = {
        requirements_id: this.requirementData.id,
        image_file: this.file,
        list_procedure_id: this.personalDetailProcedure.id,
      };
      // console.log(params);
      this.$store.dispatch("user/uploadFile", params).then(() => {
        this.uploadRequirement();
      });
    },

    updateFile() {
      let params = {
        requirements_id: this.requirementData.id,
        image_file: this.file,
        status: "Pendiente",
        list_procedure_id: this.personalDetailProcedure.id,
        observation: "Requisito Actualizado",
        id: this.requirementData.id,
      };
      this.$store.dispatch("user/updateFile", params).then(() => {
        this.updateRequirement();
      });
    },
    renderDocumentUploaded(id) {
      let data = null;
      this.personalDetailProcedure.uploadrequeriments.forEach((item) => {
        if (item.requirements_id == id) {
          data = item;
        }
      });
      if (data) {
        return this.renderStatus(data.status, "text");
      } else {
        return "";
      }
    },
    isDocumentUploaded(id) {
      let exists = false;
      this.personalDetailProcedure.uploadrequeriments.forEach((item) => {
        if (item.requirements_id == id) {
          exists = true;
        }
      });
      return exists;
    },
    isInRevision(id) {
      let exists = "";
      let uploadFiles = this.personalDetailProcedure.uploadrequeriments;
      let position = uploadFiles.findIndex(
        (item) => item.requirements_id == id
      );
      if (position != -1) {
        exists = uploadFiles[position].status;
      }

      return exists;
    },
    obtenerRuta(id, type = null) {
      let route = "";
      this.personalDetailProcedure.uploadrequeriments.forEach((item) => {
        if (item.requirements_id == id) {
          this.uploadedData = item;
          route = this.server + item.image_file.replace("/public", "");
        }
      });
      if (type) {
        this.ruta = null;
      }
      this.ruta = route;
    },
    updateRequirement() {
      let params = {
        id: this.personalDetailProcedure.id,
      };
      this.$store
        .dispatch("user/getPersonalProcedureDetail", params)
        .then(() => {
          this.$store.dispatch(
            "alerts/setSnackbarText",
            "Requisito actualizado correctamente"
          );
          this.$store.dispatch("alerts/setSnackbarColor", "success");
          this.$store.dispatch("alerts/setSnackbar", true);
          this.closeDialog();
        });
    },
    uploadRequirement() {
      let params = {
        id: this.personalDetailProcedure.id,
      };
      this.$store
        .dispatch("user/getPersonalProcedureDetail", params)
        .then(() => {
          this.$store.dispatch(
            "alerts/setSnackbarText",
            "Requisito subido correctamente"
          );
          this.$store.dispatch("alerts/setSnackbarColor", "success");
          this.$store.dispatch("alerts/setSnackbar", true);
          this.closeDialog();
        });
    },
    setExpireFile() {
      var self = this;
      let params = {
        id: self.personalDetailProcedure.id,
        expire_at: self.date,
      };
      this.$store.dispatch("user/setExpire", params).then(() => {
        this.$store.dispatch("alerts/setSnackbarText", "Fecha actualizada");
        this.$store.dispatch("alerts/setSnackbarColor", "success");
        this.$store.dispatch("alerts/setSnackbar", true);
        self.dialog2 = false;
        self.actualizar();
      });
    },
    actualizar() {
      let params = {
        id: this.personalDetailProcedure.id,
      };
      this.$store.dispatch("user/getPersonalProcedureDetail", params);
    },
  },
  computed: {
    ...mapState({
      personalDetailProcedure: (state) => state.user.personalProcedureDetail,
      server: (state) => state.user.server,
      userLogued: (state) => state.user.loguedUser,
      isAdminReview: (state) => state.user.isProcedureInReview,
    }),
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

.TitleT {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 15px;
  /* or 125% */
  color: #393e46;
}
.textP {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 15px;
  letter-spacing: 0.07px;
  color: #9b2040;
}
.textD {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 15px;
  letter-spacing: 0.07px;
  color: #393e46;
}
.textI {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.07px;
  color: #222831;
}
.login-container {
  height: 100%;
  width: 100%;
}
.backgroundGray {
  background: rgba(247, 247, 248, 255);
}
.createAccount {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 23px;
  color: #9b2040;
}
.seconText {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 23px;
  color: #222831;
}
.inputC {
  box-sizing: border-box;
  border-radius: 12px;
}
.infoText {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 15px;
  letter-spacing: 0.07px;
  color: #393e46;
}
.dataText {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 15px;
  letter-spacing: 0.07px;
  color: #222831;
}
.textLD {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 19px;
  letter-spacing: 0.07px;
  color: #222831;
}
.reqP1 {
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
}
.reqP2 {
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;
}
</style>
