<template>
  <v-card outlined @click="clickCard">
    <v-row class="mx-0">
      <v-col
        cols="2"
        class="d-flex justify-center align-center"
        :class="
          status == 'Aprobada'
            ? 'success lighten-5'
            : status == 'Pendiente'
            ? 'warning lighten-3'
            :  status == 'Rechazada' ? 'primary lighten-5':'grey lighten-3 '
        "
      >
        <v-icon large v-if="status == 'Aprobada'" color="success">
          mdi-checkbox-marked-circle-outline
        </v-icon>
        <v-icon large v-else-if="status == 'Pendiente'" color="warning">
          mdi-alert-outline
        </v-icon>        
        <v-icon color="primary darken-3" large v-else>
          mdi-alert-circle-outline
        </v-icon>
      </v-col>
      <v-col cols="10">
        <h3 class="text--center dataText mb-3">
          {{ requirementTitle }}
        </h3>
        <p class="text--center text--disabled mb-0">{{ requirementType }}</p>
      </v-col>
    </v-row>
  </v-card>
</template>
<style>
.v-card {
  overflow: hidden;
}
</style>
<script>
  export default {
    props: {
      requirementTitle: {
        type: String,
        required: true,
        default: "",
      },
      requirementType: {
        type: String,
        required: true,
        default: "",
      },
    clickCard: {
      type: Function,
      default: ()=>{},
    },
      completeRequirement: {
        type: Boolean,
        default: false,
      },
    isGrey: {
      type: Boolean,
      default: false,
    },      
    status :{
      type: String
    }
    },
  };
</script>