<template>
  <v-card outlined @click="pickFile">
    <v-row class="mx-0">
      <v-col
        cols="2"
        class="d-flex justify-center align-center"
        :class="value ? 'success lighten-5' : 'primary lighten-5'"
      >
        <v-icon large v-if="value" color="success">
          mdi-checkbox-marked-circle-outline
        </v-icon>
        <v-icon color="primary darken-3" large v-else>
          mdi-alert-circle-outline
        </v-icon>
      </v-col>
      <v-col cols="10">
        <div>
          <span
            class="text--center mb-3"
            :class="
              value ? 'darkgray--text ' : 'primary--text font-weight-bold'
            "
          >
            Selecciona imagen del requisito
          </span>
          <br />
          <span v-if="value" class="success--text">
            1 archivo seleccionado
          </span>
        </div>
        <input
          ref="image"
          type="file"
          accept="image/*"
          style="display: none"
          @change="handleFileChange"
        />
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
export default {
  props: {
    value: File,
  },
  methods: {
    handleFileChange(e) {
      this.$emit("input", e.target.files[0]);
    },
    pickFile() {
      this.$refs.image.click();
    },
  },
};
</script>

<style scoped>
</style>