import Vue from "vue";
import Router from "vue-router";
import Initial from "./views/Initial.vue";
import Home from "./views/Home.vue";
import Login from "./views/Login.vue";
import Myprocedures from "./views/MyProcedures.vue";
import PaymentComplete from "./views/PaymentComplete.vue";
import Register from "./views/Register.vue";
import TramitDetail from "./views/ProcedureDetail.vue";
import PersonalTramitDetail from "./views/PersonalProcedureDetail.vue";
import ResetPassword from "./views/ResetPassword.vue";
import AdminProcedures from "./views/AdminProcedures.vue";
import AdminProcedureArchive from "./views/AdminProcedureArchive.vue";
import Admin from "./views/AdminView.vue";
import CreateProcedure from "./views/CreateProcedure";
import AdminUser from "./views/AdminUsers";
import CreateUser from "./views/CreateUser";
//Oficialia de partes
import Oficialia from "./views/oficialia/Oficialia";
import RegisterDocument from "./views/oficialia/RegisterDocument";
import AdminOficialia from "./views/oficialia/AdminOficialia";
import DocumentDetail from "./views/oficialia/DocumentDetail";
import ConsultExpedient from "./views/oficialia/ConsultExpedient";

Vue.use(Router);
export default new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/",
      name: "initial",
      component: Initial,
    },
    {
      path: "/tramites",
      name: "tramites",
      component: Home,
    },
    {
      path: "/ingresar",
      name: "ingresar",
      component: Login,
    },
    {
      path: "/mis-tramites",
      name: "mistramites",
      component: Myprocedures,
    },
    {
      path: "/payment-complete",
      name: "paymentcomplete",
      component: PaymentComplete,
    },
    {
      path: "/registrar",
      name: "registrar",
      component: Register,
    },
    {
      path: "/detalle-tramite",
      name: "detalletramite",
      component: TramitDetail,
    },
    {
      path: "/my-tramit-detail",
      name: "mytramitdetail",
      component: PersonalTramitDetail,
    },
    {
      path: "/recuperar-contrasena",
      name: "recuperarcontrasena",
      component: ResetPassword,
    },
    {
      path: "/admin-tramites",
      name: "admintramites",
      component: AdminProcedures,
    },
    {
      path: "/home",
      name: "home",
      component: Home,
    },
    {
      path: "/login",
      name: "login",
      component: Login,
    },
    {
      path: "/my-procedures-list",
      name: "myprocedures",
      component: Myprocedures,
    },
    {
      path: "/register",
      name: "register",
      component: Register,
    },
    {
      path: "/tramit-detail",
      name: "tramitdetail",
      component: TramitDetail,
    },
    {
      path: "/admin",
      name: "adnin",
      component: Admin,
    },
    {
      path: "/create-procedure",
      name: "createprocedure",
      component: CreateProcedure,
    },
    {
      path: "/users",
      name: "users",
      component: AdminUser,
    },
    {
      path: "/create-user",
      name: "createuser",
      component: CreateUser,
    },

    {
      path: "/tramites-archives",
      name: "tramitesarchivados",
      component: AdminProcedureArchive,
    },
    {
      path: "/oficialia",
      name: "oficialia",
      component: Oficialia,
    },
    {
      path: "/registrar-documento",
      name: "registrar-documento",
      component: RegisterDocument,
    },
    {
      path: "/documentos",
      name: "Documentos",
      component: AdminOficialia,
    },
    {
      path: "/detalle-documento",
      name: "detalle-documento",
      component: DocumentDetail,
    },
    {
      path: "/consultar-expediente",
      name: "consultar-expediente",
      component: ConsultExpedient,
    },
    {
      path: "*",
      redirect: "/",
    },
  ],
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});
