<template>
  <v-dialog v-model="showModal" :max-width="getMaxWidth" persistent>
    <v-card :tile="$vuetify.breakpoint.smAndDown">
      <v-card-text class="pa-0">
        <v-row>
          <v-col
            cols="12"
            md="5"
            class="d-flex justify-center align-center success lighten-2"
            v-if="$vuetify.breakpoint.mdAndUp"
          >
            <v-img src="/imagenes/done.svg" contain height="30vh"></v-img>
          </v-col>
          <v-col cols="12" md="7">
            <v-card-text>
              <div class="d-flex justify-center align-center flex-column">
                <v-icon :color="color" size="80">{{ icon }}</v-icon>
                <h3 class="success--text text-center mt-5 mb-3">
                  {{ title }}
                </h3>
              </div>
              <p class="text-center">
                {{ text }}
              </p>
              <p class="text-center font-weight-bold info--text">
                Datos para consultar tu expediente
              </p>
              <v-alert
                color="info"
                outlined
                text
                prominent
                icon="mdi-information"
                dense
              >
                Número de oficio:
                <br />
                <strong>{{ documentSelected.folio_expedient }}</strong>
                <br />
                Código de seguridad:
                <br />
                <strong>{{ documentSelected.secure_code }}</strong>
              </v-alert>
            </v-card-text>

            <v-card-actions>
              <v-row class="mx-0" align="center" justify="center">
                <v-col cols="12">
                  <v-btn color="primary" rounded block large @click="openFile">
                    <v-icon left>mdi-file-download-outline</v-icon>
                    Descargar acuse
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-actions>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapState } from "vuex";
export default {
  props: {
    showModal: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "Expediente registrado con éxito",
    },
    text: {
      type: String,
      default:
        "Tu expediente se ha recibido correctamente en la oficialia de partes, consulta el acuse de tu entrega para darle seguimiento en línea",
    },
    onPressCancel: {
      type: Function,
      default: () => {},
    },
    onPressConfirm: {
      type: Function,
      default: () => {},
    },
    secure_code: {
      type: String,
      default: "",
    },
    folio_expedient: {
      type: String,
      default: "",
    },
    color: {
      type: String,
      default: "success",
    },
    icon: {
      type: String,
      default: "mdi-check-circle-outline",
    },
  },
  methods: {
    openFile() {
      window.open(this.server + this.documentSelected.route, "_blank");
      this.$router.go(-1);
    },
  },
  computed: {
    getMaxWidth() {
      if (this.$vuetify.breakpoint.mdAndDown) {
        return "100vw";
      }
      if (this.$vuetify.breakpoint.lg) {
        return "70vw";
      }
      return "60vw";
    },
    ...mapState({
      documentSelected: (state) => state.oficialia.documentSelected,
      server: (state) => state.user.server,
    }),
  },
};
</script>