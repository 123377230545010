import Vue from "vue";
import Vuex from "vuex";
import App from "./App.vue";
import router from "./router";
import vuetify from "./plugins/vuetify";
import store from "./stores";
import axios from "axios";
import moment from "moment";
import VueAxios from "vue-axios";
import VueMoment from "vue-moment";
import Vue2Filters from "vue2-filters";
import "./filters";

moment.locale("es");
Vue.use(Vuex);
Vue.use(VueAxios, axios);
Vue.use(VueMoment, { moment });
Vue.use(Vue2Filters);

Vue.config.productionTip = false;
new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
