import axios from "axios";

const state = {
  documents: [],
  documentSelected: {},
  isLoading: false,
};
const types = {
  SET_DOCUMENTS: "SET_DOCUMENTS",
  SET_IS_LOADING: "SET_IS_LOADING",
  SET_DOCUMENT_SELECTED: "SET_DOCUMENT_SELECTED",
};
const actions = {
  registerDocument({ commit, rootState }, data) {
    commit(types.SET_IS_LOADING, true);
    return new Promise((resolve) => {
      //axios request with form data
      axios
        .post(rootState.user.server + "/document-parts/store", data)
        .then((response) => {
          commit(types.SET_DOCUMENT_SELECTED, response.data);
          commit(types.SET_IS_LOADING, false);
          resolve(response.data);
        });
    });
  },
  async getDocumentList({ commit, rootState }) {
    const AuthStr = "Bearer " + rootState.user.userToken;
    const response = await axios.post(
      rootState.user.server + "/document-parts/list",
      {},
      {
        headers: { Authorization: AuthStr }, // Usa el token en la cabecera de la solicitud
      }
    );
    commit(types.SET_DOCUMENTS, response.data);
    commit(types.SET_IS_LOADING, false);
    return response.data;
  },
  async getDocument({ commit, rootState }, payload) {
    commit(types.SET_IS_LOADING, true);
    const AuthStr = "Bearer " + rootState.user.userToken;
    const response = await axios
      .post(rootState.user.server + "/document-parts/get", payload, {
        headers: { Authorization: AuthStr }, // Usa el token en la cabecera de la solicitud
      })
      .catch((error) => {
        commit(types.SET_IS_LOADING, false);
        commit(types.SET_DOCUMENT_SELECTED, {});
        return error;
      });
    if (response.data) {
      commit(types.SET_DOCUMENT_SELECTED, response.data);
      commit(types.SET_IS_LOADING, false);
    }
    return response;
  },

  setDocumentSelected({ commit }, payload) {
    return new Promise((resolve) => {
      commit(types.SET_DOCUMENT_SELECTED, payload);
      resolve(payload);
    });
  },
  setIsLoading({ commit }, payload) {
    commit(types.SET_IS_LOADING, payload);
  },
  updateDocumentStatus({ commit, dispatch, rootState }, payload) {
    commit(types.SET_IS_LOADING, true);

    const AuthStr = "Bearer " + rootState.user.userToken;
    return new Promise((resolve) => {
      axios
        .post(
          rootState.user.server + "/document-parts/update",
          {
            id: state.documentSelected.id,
            status: payload,
          },
          {
            headers: { Authorization: AuthStr }, // Usa el token en la cabecera de la solicitud
          }
        )
        .then((response) => {
          dispatch("getDocument", {
            folio_expedient: state.documentSelected.folio_expedient,
            secure_code: state.documentSelected.secure_code,
          });
          resolve(response.data);
        });
    });
  },
  updateDocumentRecord({ commit, dispatch, rootState }, payload) {
    commit(types.SET_IS_LOADING, true);
    let formData = new FormData();
    if (payload.file) {
      formData.append("file", payload.file);
    }
    formData.append("user_id", rootState.user.loguedUser.id);
    formData.append("document_part_id", state.documentSelected.id);
    formData.append("comment", payload.comment);
    const AuthStr = "Bearer " + rootState.user.userToken;
    return new Promise((resolve) => {
      axios
        .post(
          rootState.user.server + "/document-parts/store-follow",
          formData,
          {
            headers: {
              Authorization: AuthStr,
              "Content-Type": "multipart/form-data",
            }, // Usa el token en la cabecera de la solicitud
          }
        )

        .then((response) => {
          dispatch("getDocument", {
            folio_expedient: state.documentSelected.folio_expedient,
            secure_code: state.documentSelected.secure_code,
          });
          resolve(response.data);
        });
    });
  },
};
const mutations = {
  [types.SET_DOCUMENTS](state, data) {
    state.documents = data;
  },
  [types.SET_DOCUMENT_SELECTED](state, data) {
    state.documentSelected = data;
  },
  [types.SET_IS_LOADING](state, payload) {
    state.isLoading = payload;
  },
};
export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
