var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-card',[_c('v-card-title',[_c('v-row',{staticClass:"mx-0"},[_c('v-col',{attrs:{"cols":"12","md":"8"}},[_c('p',{staticClass:"mb-0 primary--text"},[_c('b',[_vm._v(" Oficialia de partes ")])]),_c('p',{staticClass:"mb-0"},[_c('small',[_vm._v(_vm._s(_vm.documents.length)+" expedientes recibidos")])])]),_c('v-col',{staticClass:"d-flex justify-end align-center",attrs:{"cols":"12","md":"4"}},[_c('v-btn',{attrs:{"to":"/registrar-documento","large":"","rounded":"","color":"primary","dark":""}},[_vm._v(" Registrar un documento ")])],1)],1)],1),_c('v-card-text',[_c('v-row',{staticClass:"mx-0"},[_c('v-col',{attrs:{"cols":"12","md":"7"}},[_c('v-text-field',{staticClass:"my-2",attrs:{"label":"Buscar por expediente","outlined":"","dense":"","clearable":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',{attrs:{"cols":"12","md":"5"}},[_c('v-select',{staticClass:"my-2",attrs:{"items":_vm.status_types,"label":"Filtrar por estatus","outlined":"","dense":""},model:{value:(_vm.status),callback:function ($$v) {_vm.status=$$v},expression:"status"}})],1)],1),_c('v-data-table',{staticClass:"mytable",attrs:{"cols":"12","no-data-text":"No hay usuarios registrados","headers":_vm.headers,"items":_vm.dataFiltered,"items-per-page":20,"loading":_vm.isLoading,"loading-text":"Cargando expedientes","d-initial-sort":"","item-key":"id"},scopedSlots:_vm._u([{key:"item.type",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"font-weight-medium",attrs:{"color":"info","label":"","outlined":"","small":""}},[_vm._v(" "+_vm._s(item.type)+" ")])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"font-weight-medium",attrs:{"color":_vm.getColor(item.status),"label":"","small":""}},[_vm._v(" "+_vm._s(item.status)+" ")])]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("date")(item.created_at))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"outlined":"","color":"primary","rounded":""}},on),[_vm._v(" Opciones "),_c('v-icon',{attrs:{"right":""}},[_vm._v("mdi-chevron-down")])],1)]}}],null,true)},[_c('v-list',{attrs:{"color":"white"}},[_c('v-list-item',{on:{"click":function($event){return _vm.goToDocumentDetails(item)}}},[_c('v-icon',{attrs:{"color":"black","left":""}},[_vm._v("mdi-eye-outline")]),_c('v-list-item-title',{staticClass:"black--text"},[_vm._v(" Ver expediente ")])],1)],1)],1)]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }