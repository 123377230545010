import Vue from "vue";
import Vuex from "vuex";
import user from "./user";
import alerts from "./alerts";
import procedures from "./procedures";
import oficialia from "./oficialia";
Vue.use(Vuex);
const store = new Vuex.Store({
  modules: {
    user,
    procedures,
    alerts,
    oficialia,
  },
});
export default store;
