 <template>
    <v-row >
      <v-col cols="12" class="align-center justify-center  pt-12 ">
      <v-card :tile="$vuetify.breakpoint.smAndDown" class="mx-9">
        <v-card-title class="primary--text justify-center">
          <h4 class="text-center">Detalles del trámite</h4>
        </v-card-title>
        <v-row>
          <v-col>
            <v-text-field
              class="text-center mx-5"
              v-model="name"
              label="Nombre" 
              required
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-textarea
              class="text-center mx-5"
              v-model="description"
              label="Descripción"
              required
            ></v-textarea>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-text-field
              class="text-center mx-5"
              v-model="price"
              label="Precio"
              required
            ></v-text-field> 
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-select
              class="text-center mx-5"
              v-model="objetivePublic"
              label="Público objetivo"
              :items="objetivePublics"  
            ></v-select>                        
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-select
              class="text-center mx-5"
              v-model="tramitType"
              :items="typeTramits"
              label="Tipo de trámite"
            ></v-select>            
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-textarea
              class="text-center mx-5"
              v-model="tramitObjetive"
              label="Objetivo del trámite"
              required
            ></v-textarea>
          </v-col>
        </v-row>    
        <v-row>
          <v-col>
            <v-text-field
              class="text-center mx-5"
              v-model="timeService"
              label="Tiempo de validación del servicio"
              required
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-select
              class="text-center mx-5"
              v-model="modality"
              label="Modalidad"
              :items="modalitys"
              required
            ></v-select>                        
          </v-col>
        </v-row>            
        <v-card-actions class="justify-center">
          <v-btn color="error" text @click="onBack()">
            Cancelar
          </v-btn>
          <v-btn color="primary" @click="onPressConfirm()"> Confirmar </v-btn>
        </v-card-actions>
      </v-card>
      </v-col>
    </v-row>
</template>
<script>

import { mapState } from "vuex";

export default {

  mounted() {
    var self = this;
    if(this.editFlag){
      self.name = this.tramitData.name;
      self.description = this.tramitData.description;
      self.price = this.tramitData.price;
      self.objetivePublic = this.tramitData.objetive;
      self.tramitType = this.tramitData.type;
      self.modality = this.tramitData.modality;
    }
  },   
  data: () => ({
    name: "",
    description: "",
    price: "",
    objetivePublic: "", 
    tramitType: "",  
    tramitObjetive: "",
    modality: "",
    timeService: "",
    typeTramits:["Obligación","Servicio","Consulta","Conservación","Inicio de procedimiento."],
    objetivePublics:[
      "Negocios - Comercio y Particulares",
      "Negocios - Toda persona que cuente con un establecimiento comercial",
      "Negocios. Comerciantes en pequeño. Personas fisicas",
      "Negocios. Concesionarios de locales dentro de los mercados municipales Amado Nervo, Heriberto Casas y Del Mar",
      "Negocios. Ser concesionario titular de locales dentro de los mercados municipales",
      "Negocios. Toda persona que cuente con una licencia de funcionamiento y que por la naturaleza de su giro así lo requiera",
      "Persona moral. Negocios. Toda persona que cuente con un establecimiento comercial",
      "Personas físicas. Negocios. Toda persona que cuente con un establecimiento comercial",
      "Público en general",
      "Público en General - A todas las personas que por infringir el Reglamento de Tránsito y Movilidad del Municipio de Tepic",
      "Público en General - Ciudadanos que infrinjan el Reglamento de Tránsito y Movilidad",
      "Público en General - Poblacion en general",
      "Público en general- Ciudadanía en general",
      "Público en general- Personas físicas y/o morales que soliciten tala o poda de árboles."
      ],
      modalitys:["En linea","Presencial"]
   }),
   methods:{
     onBack(){
       this.$router.go(-1);
       this.clearInputs();
     },
     clearInputs(){
       this.name="";
        this.description= "";
        this.price="";
        this.objetivePublic= "";
        this.tramitType="";
        this.tramitObjetive="";
        this.modality="";
        this.timeService="";       
     },
    validateInputs(){
      let valid= true;
      if(this.name == ""){
        this.$store.dispatch(
            "alerts/setSnackbarText",
            "Nombre vacío, por favor ingrese un nombre."
          );
          this.$store.dispatch("alerts/setSnackbarColor", "warning");
          this.$store.dispatch("alerts/setSnackbar", true);
          valid= false;
      }
      else if(this.description == ""){
        this.$store.dispatch(
            "alerts/setSnackbarText",
            "Descripción vacía, por favor ingrese una descripción."
          );
          this.$store.dispatch("alerts/setSnackbarColor", "warning");
          this.$store.dispatch("alerts/setSnackbar", true);
          valid= false;
      }
      else if(this.price == ""){
        this.$store.dispatch(
            "alerts/setSnackbarText",
            "Precio vacío, por favor ingrese el precio."
          );
          this.$store.dispatch("alerts/setSnackbarColor", "warning");
          this.$store.dispatch("alerts/setSnackbar", true);
          valid= false;
      }
      else if(this.objetivePublic == ""){
        this.$store.dispatch(
            "alerts/setSnackbarText",
            "Público objetivo vacío, por favor ingrese el público objetivo."
          );
          this.$store.dispatch("alerts/setSnackbarColor", "warning");
          this.$store.dispatch("alerts/setSnackbar", true);
          valid= false;
      }
      else if(this.timeService == ""){
        this.$store.dispatch(
            "alerts/setSnackbarText",
            "Tiempo de validación vacío, por favor ingrese un tiempo de validación."
          );
          this.$store.dispatch("alerts/setSnackbarColor", "warning");
          this.$store.dispatch("alerts/setSnackbar", true);
          valid= false;
      }
      else if(this.tramitType == ""){
        this.$store.dispatch(
            "alerts/setSnackbarText",
            "Tipo de trámite vacío, por favor ingrese un tipo de trámite."
          );
          this.$store.dispatch("alerts/setSnackbarColor", "warning");
          this.$store.dispatch("alerts/setSnackbar", true);
          valid= false;
      }
      else if(this.tramitObjetive == ""){
        this.$store.dispatch(
            "alerts/setSnackbarText",
            "Objetivo del trámite vacío, por favor ingrese el objetivo del trámite."
          );
          this.$store.dispatch("alerts/setSnackbarColor", "warning");
          this.$store.dispatch("alerts/setSnackbar", true);
          valid= false;
      }
      else if(this.modality == ""){
        this.$store.dispatch(
            "alerts/setSnackbarText",
            "Modalidad vacía, por favor ingrese una modalidad."
          );
          this.$store.dispatch("alerts/setSnackbarColor", "warning");
          this.$store.dispatch("alerts/setSnackbar", true);
          valid= false;
      }
        return valid;
    },
   editTramit() {
     if(this.validateInputs()){
        let params = {
          name: this.name,
          description: this.description,
          price: this.price,
          objetive : this.objetivePublic,
          validate_service: this.timeService,
          type: this.tramitType,
          serves_for: this.tramitObjetive,
          modality: this.modality,
          dependency_id: this.loguedUser.dependency_id,
        };     
        this.$store
          .dispatch("user/editProcedure", params)
          .then(() => {
            this.$store.dispatch(
              "alerts/setSnackbarText",
              "Trámite editado correctamente."
            );
            this.$store.dispatch("alerts/setSnackbarColor", "success");
            this.$store.dispatch("alerts/setSnackbar", true);
             this.showModalEditarTramite=false;
             this.cargarDatos();
          })
          .catch(() => {
            this.$store.dispatch(
              "alerts/setSnackbarText",
              "Algo salió mal."
            );
            this.$store.dispatch("alerts/setSnackbarColor", "error");
            this.$store.dispatch("alerts/setSnackbar", true);
          });
     }
      
    },
    createTramit() {
      if(this.validateInputs()){
        let params = {
          name: this.name,
          description: this.description,
          price: this.price,
          objetive : this.objetivePublic,
          validate_service: this.timeService,
          type: this.tramitType,
          serves_for: this.tramitObjetive,
          modality: this.modality,
          dependency_id: this.loguedUser.dependency_id,
        };
        this.$store
          .dispatch("user/registerProcedure", params)
          .then(() => {
            this.$store.dispatch(
              "alerts/setSnackbarText",
              "Trámite creado correctamente."
            );
            this.$store.dispatch("alerts/setSnackbarColor", "success");
            this.$store.dispatch("alerts/setSnackbar", true);
             this.showModalCrearTramite=false;
             this.cargarDatos();
          })
          .catch(() => {
            this.$store.dispatch(
              "alerts/setSnackbarText",
              "Algo salió mal."
            );
            this.$store.dispatch("alerts/setSnackbarColor", "error");
            this.$store.dispatch("alerts/setSnackbar", true);
          });
      }
    },     
     onPressConfirm(){
       //Agregar conndicion para valida si va a editar o agregar uno nuevo
     }
   },
    computed: {
    ...mapState({
      adminData: (state) => state.user.adminData,
      isLoading: (state) => state.user.isLoading,  
      tramitData:(state) => state.user.adminProcedure,
      editFlag: (state) => state.user.procedureEditFlag, 
      
    }),
  },  
};
</script>