import axios from "axios";
const types = {
  SET_PROCEDURE_LIST: "SET_PROCEDURE_LIST",
  PROCEDURE_DETAIL: "PROCEDURE_DETAIL",
  SET_SEARCH: "SET_SEARCH",
  SET_DEPENDENCY_LIST: "SET_DEPENDENCY_LIST",
  SET_IS_LOADING: "SET_IS_LOADING",
};
const state = {
  procedureList: [],
  procedureDetail: {
    id: 1,
    dependency_id: 6,
    name: "",
    description: "",
    price: "0",
    objetive: "",
    status_service: "",
    validate_service: null,
    modality: "",
    serves_for: "",
    type: "",
    mode: "",
    created_at: null,
    updated_at: null,
    dependecy: {
      id: 6,
      name: "",
      address: "",
      schedule: "",
      tel: null,
      longitude: null,
      latitude: null,
      image: null,
      created_at: null,
      updated_at: null,
    },
  },
  search: "",
  //server: "http://127.0.0.1:8000",
  server: "https://backend-tramites-2jxoq.ondigitalocean.app",
  dependecyList: [],
  isLoading: false,
};
const actions = {
  setProcedureList({ commit }, params) {
    let formData = new FormData();
    formData.append("search", params.search);
    return new Promise((resolve) => {
      axios
        .post(state.server + "/procedures/list", formData, {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "multipart/form-data",
          },
        })
        .then(({ data }) => {
          commit(types.SET_PROCEDURE_LIST, data);
          resolve();
        });
    });
  },
  setProcedureData({ commit }, data) {
    return new Promise((resolve) => {
      commit(types.PROCEDURE_DETAIL, data);
      resolve();
    });
  },
  getProcedureDetail({ commit }, data) {
    commit(types.SET_IS_LOADING, true);
    let formData = new FormData();
    formData.append("id", data.id);
    return new Promise((resolve) => {
      axios
        .post(state.server + "/procedures/get", formData, {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "multipart/form-data",
          },
        })
        .then(({ data }) => {
          commit(types.PROCEDURE_DETAIL, data);
          commit(types.SET_IS_LOADING, false);
          resolve(data);
        });
    });
  },
  setSearch({ commit }, string) {
    commit(types.SET_SEARCH, string);
  },
  getDependencyList({ commit }) {
    return new Promise((resolve) => {
      axios
        .post(
          state.server + "/dependencies/list",
          {},
          {
            headers: {
              "Access-Control-Allow-Origin": "*",
              "Content-Type": "multipart/form-data",
            },
          }
        )
        .then(({ data }) => {
          commit(types.SET_DEPENDENCY_LIST, data);
          resolve();
        });
    });
  },
  getCategories({ commit }) {
    return new Promise((resolve) => {
      axios
        .post(
          state.server + "/requirements/categories-list",
          {},
          {
            headers: {
              "Access-Control-Allow-Origin": "*",
              "Content-Type": "multipart/form-data",
            },
          }
        )
        .then(({ data }) => {
          commit(types.SET_IS_LOADING, false);
          resolve(data);
        });
    });
  },
  dowloadProcedurePattern({ commit }, params) {
    let url = state.server + "/procedures/pdf-requisitos/" + params.id + "";
    return new Promise((resolve, reject) => {
      axios
        .get(url)
        .then((data) => {
          resolve(data);
          console.log(data);
          commit(types.SET_IS_LOADING, false);
        })
        .catch((error) => {
          reject();
          console.log(error);
        });
    });
  },
};
const mutations = {
  [types.SET_PROCEDURE_LIST](state, data) {
    state.procedureList = data;
  },
  [types.PROCEDURE_DETAIL](state, data) {
    state.procedureDetail = data;
  },
  [types.SET_SEARCH](state, data) {
    state.search = data;
  },
  [types.SET_DEPENDENCY_LIST](state, data) {
    state.dependecyList = data;
  },
  [types.SET_IS_LOADING](state, data) {
    state.isLoading = data;
  },
};
export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
